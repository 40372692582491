import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { AvailabilityInquiry } from '../../models/availability-inquiry';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityInquiryService {
  private _baseUrl: string;

  private _httpClient = inject(HttpClient);
  private _appConfig = inject<ConfigModelWeb>(appConfigToken);
  private _libTranslation = inject(libTranslationConfigToken);

  constructor() {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this._baseUrl = apiUrl + apiVersionMain + '/availabilityinquiries';
  }

  getAvailabilityInquiry(inquiryId: string): Observable<AvailabilityInquiry> {
    const url = `${this._baseUrl}/${inquiryId}`;
    return this._httpClient.get<AvailabilityInquiry>(url);
  }
}
