import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgOptimizedImage } from '@angular/common';
import { travelerTranslationToken } from '../../config/tokens';
import { Language, TravelerTranslationModel } from '../../models/translation.model';
import { translationDK } from '../../i18n/dk';
import { translationSV } from '../../i18n/sv';
import { translationEN } from '../../i18n/en';
import { translationFI } from '../../i18n/fi';
import { translationNB } from '../../i18n/nb';

@Component({
  selector: 'traveler-language-picker',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatMenuModule, NgOptimizedImage],
  template: `
    <button mat-icon-button color="secondary" [matMenuTriggerFor]="menu">
      <img [ngSrc]="logo()" width="18" height="18" />
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="languageChanged(language.EN)">
        <div class="flex flex-row gap-2 items-center">
          <img ngSrc="assets/icons/british.svg" width="26" height="26" />
          <span>English</span>
        </div>
      </button>
      <button mat-menu-item (click)="languageChanged(language.DK)">
        <div class="flex flex-row gap-2 items-center">
          <img ngSrc="assets/icons/danish.svg" width="26" height="26" />
          <span>Dansk</span>
        </div>
      </button>
      <button mat-menu-item (click)="languageChanged(language.FI)">
        <div class="flex flex-row gap-2 items-center">
          <img ngSrc="assets/icons/finish.svg" width="26" height="26" />
          <span>Suomeksi</span>
        </div>
      </button>
      <button mat-menu-item (click)="languageChanged(language.NB)">
        <div class="flex flex-row gap-2 items-center">
          <img ngSrc="assets/icons/norwegian.svg" width="26" height="26" />
          <span>Norsk</span>
        </div>
      </button>
      <button mat-menu-item (click)="languageChanged(language.SV)">
        <div class="flex flex-row gap-2 items-center">
          <img ngSrc="assets/icons/swedish.svg" width="26" height="26" />
          <span>Svenska</span>
        </div>
      </button>
    </mat-menu>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent implements OnInit {
  logo = signal('assets/icons/swedish.svg');
  language = Language;

  private _translation = inject<WritableSignal<TravelerTranslationModel>>(travelerTranslationToken);

  ngOnInit() {
    const currentLanguage: Language = this.getCurrentLanguage();

    this.setLogo(currentLanguage);
  }

  getCurrentLanguage(): Language {
    return this._translation().language;
  }

  setLogo(language: Language) {
    switch (language) {
      case Language.SV: {
        this.logo.set('assets/icons/swedish.svg');
        break;
      }
      case Language.EN: {
        this.logo.set('assets/icons/british.svg');
        break;
      }
      case Language.NB: {
        this.logo.set('assets/icons/norwegian.svg');
        break;
      }
      case Language.FI: {
        this.logo.set('assets/icons/finish.svg');
        break;
      }
      case Language.DK: {
        this.logo.set('assets/icons/danish.svg');
        break;
      }
    }
  }

  languageChanged(language: Language) {
    this.setLogo(language);
    localStorage.setItem('language', language);
    switch (language) {
      case Language.SV: {
        this._translation.set(translationSV);
        break;
      }
      case Language.EN: {
        this._translation.set(translationEN);
        break;
      }
      case Language.FI: {
        this._translation.set(translationFI);
        break;
      }
      case Language.DK: {
        this._translation.set(translationDK);
        break;
      }
      case Language.NB: {
        this._translation.set(translationNB);
        break;
      }
      default: {
        this._translation.set(translationEN);
      }
    }
  }
}
