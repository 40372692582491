export interface NotificationMessage {
  id: number;
  title: string;
  main: string;
  submain: string;
  type: string;
  notificationType: NotificationType;
  data: { [key: string]: unknown };
  link: string;
  groups: string[];
  isRead: boolean;
  created: string;
}

export enum NotificationType {
  DriveOrderInquiryCreated = 1,
  DriveOrderAssigned = 2,
}

export interface DriveOrderInquiryCreatedType {
  numberOfInquiries: number;
}

export interface DriveOrderAssignedType {
  numberOfDriveOrders: number;
}
