import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { format, set } from 'date-fns';
import { libTranslationConfigToken } from '../../utils/injection-tokens';
import { LibTranslation } from '../../i18n/lib-translation.model';

@Component({
  selector: 'tbums-lib-date-time-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatListModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  template: `
    <mat-form-field
      subscriptSizing="dynamic"
      cdkOverlayOrigin
      #originOverlay="cdkOverlayOrigin"
      class="relative w-full"
      [ngClass]="{ 'relative z-[100]': showPanel() }"
    >
      <mat-label
        >{{ isStart ? libTranslation.search.filter.startDateTime : libTranslation.search.filter.endDateTime }}
      </mat-label>

      <input matInput type="text" readonly [formControl]="inputFormControl" (focus)="open()" class="cursor-pointer" />
    </mat-form-field>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="originOverlay"
      [cdkConnectedOverlayOpen]="showPanel()"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      (backdropClick)="close()"
      [cdkConnectedOverlayHasBackdrop]="true"
    >
      <div class="bg-white rounded-b shadow-2xl w-[260px] border-gray2 border-[1px] p-2 flex flex-col gap-2">
        <mat-calendar [(selected)]="selectedDate" (selectedChange)="dateChanged()"></mat-calendar>
        <div class="flex flex-row gap-1 items-center">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ libTranslation.search.filter.hours }}</mat-label>
            <mat-select [formControl]="hoursFormControl" (selectionChange)="timeSelected()">
              @for (hour of hours(); track hour) {
                <mat-option [id]="hour.toString()" [value]="hour">{{ hour }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <span>:</span>
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ libTranslation.search.filter.minutes }}</mat-label>
            <mat-select [formControl]="minutesFormControl" (selectionChange)="timeSelected()">
              @for (minute of minutes(); track minute) {
                <mat-option [id]="minute.toString()" [value]="minute">{{ minute }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-row justify-between">
          <button mat-button class="text-blue4" (click)="resetTime()">
            {{ libTranslation.search.filter.resetTime }}
          </button>
          <button mat-button color="primary" (click)="close()">{{ libTranslation.common.button.close }}</button>
        </div>
      </div>
    </ng-template>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerComponent implements OnInit {
  @Input() isStart = false;
  @Output() dateTimeChange = new EventEmitter<Date>();

  libTranslation = inject<LibTranslation>(libTranslationConfigToken);
  formBuilder = inject(FormBuilder);
  inputFormControl = this.formBuilder.control('');
  hoursFormControl = this.formBuilder.control('');
  minutesFormControl = this.formBuilder.control('');

  selectedDate = new Date();
  showPanel = signal(false);
  hours = computed(() => {
    const hours: string[] = [];
    for (let i = 0; i < 24; i++) {
      hours.push(this.addPadding(i).toString());
    }
    return hours;
  });
  minutes = computed(() => {
    const minutes: string[] = [];
    for (let i = 0; i < 60; i++) {
      minutes.push(this.addPadding(i).toString());
    }
    return minutes;
  });

  @Input({ required: true }) set date(selectedDate: Date) {
    this.selectedDate = selectedDate;
    this.minutesFormControl.setValue(this.addPadding(selectedDate.getMinutes()).toString());
    this.hoursFormControl.setValue(this.addPadding(selectedDate.getHours()).toString());
    this.setDateTimeInput();
  }

  ngOnInit() {
    this.setDateTimeInput();
  }

  dateChanged() {
    this.apply();
  }

  timeSelected() {
    this.apply();
  }

  addPadding(val: number): string {
    return val < 10 ? '0' + val : val.toString();
  }

  setDateTimeInput() {
    let dateTime = '';
    dateTime =
      format(this.selectedDate, 'yyyy-MM-dd') +
      '  ' +
      this.hoursFormControl.value +
      ':' +
      this.minutesFormControl.value;
    this.inputFormControl.setValue(dateTime);
  }

  getCurrentSelectedDateTime() {
    return set(this.selectedDate, {
      hours: parseInt(this.hoursFormControl.value!),
      minutes: parseInt(this.minutesFormControl.value!),
    });
  }

  resetTime() {
    this.minutesFormControl.setValue(this.isStart ? '00' : '59');
    this.hoursFormControl.setValue(this.isStart ? '00' : '23');
  }

  apply() {
    this.setDateTimeInput();
    this.dateTimeChange.emit(this.getCurrentSelectedDateTime());
  }

  close() {
    this.showPanel.set(false);
  }

  open() {
    this.showPanel.set(true);
  }
}
