import { OrderType } from './order-type';
import { OrderState } from './order-state';
import { DateRange } from './date-range';

export class OrderSearch {
  orderTypes: OrderType[] = [];
  orderStates: OrderState[] = [];
  dateRange!: DateRange;
  orderIds: number[] = [];
  referenceIds: string[] = [];
  driveOrderIds: number[] = [];
  operators: number[] = [];
  externalIds: string[] = [];
  tripIds: number[] = [];
  trainNumber = '';
  subContractor = '';
  pageSize!: number;
  page!: number;
}
