import { ErrorHandler, inject, Injectable, isDevMode } from '@angular/core';

import { AlertService } from './alert.service';

import { libTranslationConfigToken } from '../utils/injection-tokens';
import { LibTranslation } from '../i18n/lib-translation.model';
import { ApiError } from '../error-handling/api-error';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandlerService implements ErrorHandler {
  private _applicationInsightsService: ApplicationInsightsService = inject(ApplicationInsightsService);
  private _alertService: AlertService = inject(AlertService);
  private _translation: LibTranslation = inject<LibTranslation>(libTranslationConfigToken);

  handleError(error: unknown) {
    console.log('An ERror was thrown');
    //Error Messages
    if (error instanceof ApiError) {
      this._alertService.setError(error.message, error.operationId);
    } else if (error instanceof Error) {
      this._alertService.setError(this._translation.common.errorMessage);
    }

    //Application Insights
    if (error instanceof Error) {
      this._applicationInsightsService.logException(error);

      if (isDevMode()) {
        console.error(error);
      }
    }
  }
}
