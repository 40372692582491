import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { NotificationMessage } from '../../models/notification-message';
import { NotificationService } from '../../services/api/notification.service';

@Component({
  selector: 'tbums-lib-notifications-panel',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatBadgeModule, MatButtonModule],
  template: `
    <button mat-icon-button (click)="openDialog()">
      <mat-icon
        [matBadgeHidden]="numberOfUnreadNotifications() === 0"
        [matBadge]="numberOfUnreadNotifications()"
        matBadgeColor="warn"
        aria-hidden="false"
        >notifications
      </mat-icon>
    </button>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPanelComponent implements OnInit {
  notifications!: NotificationMessage[];
  numberOfUnreadNotifications = signal(0);

  private _destryRef = inject(DestroyRef);

  constructor(
    private _dialog: MatDialog,
    private _notificationService: NotificationService,
  ) {
    this._notificationService.notifications$
      .pipe(takeUntilDestroyed(this._destryRef))
      .subscribe((notifications: NotificationMessage[] | null) => {
        if (notifications) {
          this.notifications = notifications;
          this.setNumberOfUnreadNotifications(notifications);
        }
      });
  }

  ngOnInit(): void {
    this._notificationService.getNotifications();
  }

  openDialog(): void {
    this._dialog.open(NotificationDialogComponent, {
      data: {
        notifications: this.notifications,
        numberOfUnreadNotifications: this.numberOfUnreadNotifications,
      },
      autoFocus: false,
      minWidth: '500px',
      position: {
        top: '40px',
      },
    });
  }

  setNumberOfUnreadNotifications(notifications: NotificationMessage[]): void {
    this.numberOfUnreadNotifications.set(
      notifications.filter((notification: NotificationMessage) => {
        return !notification.isRead;
      }).length,
    );
  }
}
