import { inject, Injectable } from '@angular/core';
import { appConfigToken } from '../utils/injection-tokens';
import { ConfigModelWeb, EnvironmentType } from '../assets/config/config.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  appConfig: ConfigModelWeb = inject<ConfigModelWeb>(appConfigToken);

  get environmentColorClass() {
    //return 'env-bg-at';
    switch (this.appConfig.environmentType) {
      case EnvironmentType.Local:
        return 'env-bg-local';
      case EnvironmentType.FT:
        return 'env-bg-ft';
      case EnvironmentType.AT:
        return 'env-bg-at';
      case EnvironmentType.ST:
        return 'env-bg-st';
      case EnvironmentType.Production:
        return 'env-bg-production';
      default:
        return 'env-bg-production';
    }
  }
}
