export interface Alert {
  type: AlertType;
  message: string;
  operationId?: string;
  duration?: number;
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
}
