import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogModel } from './confirm-dialog.model';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'tbums-lib-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  template: `
    <h1 mat-dialog-title>{{ dialogData.title }}</h1>
    <mat-dialog-content>{{ dialogData.message }}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close color="warn">
        {{ dialogData.cancelBtnText }}
      </button>
      <button mat-button color="accent" [mat-dialog-close]="false" *ngIf="dialogData.secondaryActionBtnText">
        {{ dialogData.secondaryActionBtnText }}
      </button>
      <button mat-button [mat-dialog-close]="true" color="primary">
        {{ dialogData.confirmBtnText }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  dialogData = inject<ConfirmDialogModel>(MAT_DIALOG_DATA);
}
