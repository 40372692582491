import { Injectable, inject } from '@angular/core';
import { translationStringSubstitution } from '../utils/translation-util';
import { libTranslationConfigToken } from '../utils/injection-tokens';
import { LibTranslation } from '../i18n/lib-translation.model';
import { NotificationType, DriveOrderInquiryCreatedType, DriveOrderAssignedType } from '../models/notification-message';

export interface ServerNotification {
  title: string;
  message: string;
  link: string;
  isExternal: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ServerNotificationService {
  private _translation = inject<LibTranslation>(libTranslationConfigToken);

  getFormatedNotification(notificationType: NotificationType, data: { [key: string]: unknown }): ServerNotification {
    let title: string;
    let message: string;
    let link: string;

    switch (notificationType) {
      case NotificationType.DriveOrderInquiryCreated:
        ({ title, message, link } = this.setDriveOrderInquiryNotification(data));
        break;
      case NotificationType.DriveOrderAssigned:
        ({ title, message, link } = this.setDriveOrderAssignedNotification(data));
        break;

      default:
        title = '';
        message = '';
        link = '';
        break;
    }
    const isExternal = this.isExternalLink(link);
    return { title, message, link, isExternal };
  }

  isExternalLink(link: string): boolean {
    return !(link.indexOf('/order-new/') >= 0 || link.indexOf('/garage/') >= 0 || link.indexOf('planning') >= 0);
  }

  setDriveOrderAssignedNotification(data: { [key: string]: unknown }) {
    const driveOrderAssignedData = data as unknown as DriveOrderAssignedType;
    const numberOfDriveOrders = driveOrderAssignedData.numberOfDriveOrders;
    const title = this._translation.toolbar.notifications.driveOrderAssignedNotification.title;
    let message: string;
    const link = `${window.location.origin}/orders`;

    if (numberOfDriveOrders > 1) {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderAssignedNotification.severalDriveOrdersMessage,
        numberOfDriveOrders.toString(),
      );
    } else {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderAssignedNotification.oneDriveOrderMessage,
        numberOfDriveOrders.toString(),
      );
    }
    return { title, message, link };
  }

  setDriveOrderInquiryNotification(data: { [key: string]: unknown }) {
    const driveOrderInquiryCreatedData = data as unknown as DriveOrderInquiryCreatedType;
    const numberOfInquiries = driveOrderInquiryCreatedData.numberOfInquiries;
    const title = this._translation.toolbar.notifications.driveOrderInquiryCreatedNotification.title;
    let message: string;
    const link = 'planning';

    if (numberOfInquiries > 1) {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderInquiryCreatedNotification.severalDriveOrdersMessage,
        numberOfInquiries.toString(),
      );
    } else {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderInquiryCreatedNotification.oneDriveOrderMessage,
        numberOfInquiries.toString(),
      );
    }
    return { title, message, link };
  }
}
