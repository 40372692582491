import { inject, Injectable } from '@angular/core';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { appConfigToken } from '../../utils/injection-tokens';
import { HttpClient } from '@angular/common/http';
import { format } from 'date-fns';
import { SearchTrips } from '../../models/search-trip';
import { Observable } from 'rxjs';
import { TripStopPlace } from '../../models/trip-stop-place';
import { SearchDriver } from '../../models/search-driver';
import { Operator } from '../../models/operator';
import { DriveOrderTrip } from '../../models/drive-order';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  readonly baseUrl: string;
  private _appConfig: ConfigModelWeb = inject<ConfigModelWeb>(appConfigToken);
  private _httpClient = inject(HttpClient);

  constructor() {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this.baseUrl = apiUrl + apiVersionMain;
  }

  getSearchTrips$(
    startDate: Date,
    endDate: Date,
    listType?: string,
    orderId?: string,
    unboundOrderId?: string,
    driveOrderId?: string,
    tripId?: string,
    operatorsIds?: number[],
    subcontractorIds?: number[],
    placeName?: string,
    vehicleIdentifier?: string,
    driverId?: string,
    referenceId?: string,
    externalId?: string,
    trainNumber?: string,
    sortColumn?: number,
    sortDesc?: boolean,
    page?: number,
    pageSize?: number,
    includeCancelledTrips?: boolean,
  ): Observable<SearchTrips> {
    const startDateString = format(startDate, 'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX');
    const endDateString = format(endDate, 'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX');
    let queryString = `${this.baseUrl}/search/trips?StartDate=${encodeURIComponent(
      startDateString,
    )}&EndDate=${encodeURIComponent(endDateString)}`;

    if (listType) queryString += `&ListType=${listType}`;
    if (orderId) queryString += `&OrderId=${orderId}`;
    if (unboundOrderId) queryString += `&UnboundOrderId=${unboundOrderId}`;
    if (driveOrderId) queryString += `&DriveOrderId=${driveOrderId}`;
    if (tripId) queryString += `&TripId=${tripId}`;
    if (operatorsIds?.length) queryString += `&OperatorIds=${operatorsIds.join(',')}`;
    if (subcontractorIds?.length) queryString += `&SubContractorIds=${subcontractorIds.join(',')}`;
    if (placeName) queryString += `&PlaceName=${placeName}`;
    if (vehicleIdentifier) queryString += `&VehicleIdentifier=${vehicleIdentifier}`;
    if (driverId) queryString += `&DriverId=${driverId}`;
    if (referenceId) queryString += `&ReferenceId=${referenceId}`;
    if (externalId) queryString += `&ExternalId=${externalId}`;
    if (trainNumber) queryString += `&TrainNumber=${trainNumber}`;
    if (sortColumn) queryString += `&SortBy=${sortColumn}&SortDesc=${sortDesc}`;
    if (pageSize) queryString += `&PageSize=${pageSize}`;
    if (page) queryString += `&Page=${page}`;
    queryString += `&IncludeCancelledTrips=${includeCancelledTrips}`;

    return this._httpClient.get<SearchTrips>(queryString);
  }

  getTripStops$(): Observable<TripStopPlace[]> {
    return this._httpClient.get<TripStopPlace[]>(`${this.baseUrl}/tripstops/all`);
  }

  getDrivers$(): Observable<SearchDriver[]> {
    return this._httpClient.get<SearchDriver[]>(`${this.baseUrl}/drivers`);
  }

  getOperators$(): Observable<Operator[]> {
    return this._httpClient.get<Operator[]>(`${this.baseUrl}/operators`);
  }

  getTripWithTripStops$(driveOrderId: number, tripId: number): Observable<DriveOrderTrip> {
    return this._httpClient.get<DriveOrderTrip>(`${this.baseUrl}/driveorders/${driveOrderId}/trip/${tripId}`);
  }
}
