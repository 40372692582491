import { Subcontractor } from './subcontractor';
import { DriveOrderTripStop } from './drive-order';
import { OrderType } from './order-type';
import { StateObject } from './state-object';

export enum AvailabilityInquiryState {
  StateNew = 0, // OBS! Kan inte ha namnet 'New'
  Submitted = 1,
  Rejected = 2,
  Accepted = 3,
  Assigned = 4,
  Ongoing = 5,
  NotAssigned = 6,
}

export interface AvailableVehicle {
  count: number;
  estimatedArrivalMinutes: number;
}

export interface AvailabilityInquiry {
  id: number;
  subcontractor: Subcontractor;
  currentState: AvailabilityInquiryStateObject;
  numberOfVehicles: number;
  submitted: AvailabilityInquiryStateObject;
  replyInquiry: AvailabilityInquiryReply;
  assignInquiry: AvailabilityInquiryAssign;
  orderId: number;
  driveOrderDates: string[];
  startTripStop: DriveOrderTripStop;
  endTripStop: DriveOrderTripStop;
  isRoundTrip: boolean;
  startOrderRouteStopDescription: string;
  endOrderRouteStopDescription: string;
  orderRouteName: string;
  orderReason: AvailabilityInquiryReason;
  orderType: OrderType;
  vehicleType: string;
  trainNumber: string;
}

export interface AvailabilityInquiryReply {
  repliedTime: Date;
  numberOfVehiclesAvailable: number;
  estimatedTimeOfArrival: Date;
  estimatedTimeOfArrivalMinutes: number;
}

export interface AvailabilityInquiryAssign {
  assignedTime: Date;
  numberOfVehiclesAssigned: number;
}

export interface AvailabilityInquiryReason {
  id: number;
  description: string;
  additionalInfo: string;
}

export interface AvailabilityInquiryStateObject extends StateObject {
  stateEnum: AvailabilityInquiryState;
}
