import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from '../notifications/notifications.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationMessage } from '../../../models/notification-message';

export interface DialogData {
  notifications: NotificationMessage[];
  numberOfUnreadNotifications: number;
}

@Component({
  selector: 'tbums-lib-notification-dialog',
  standalone: true,
  imports: [CommonModule, NotificationsComponent],
  template: ` <tbums-lib-notifications
    [notifications]="data.notifications || []"
    [numberOfUnreadNotifications]="data.numberOfUnreadNotifications || 0"
  />`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDialogComponent {
  data: DialogData = inject(MAT_DIALOG_DATA);
}
