import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountInfo } from '@azure/msal-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BehaviorSubject, filter, map, Observable, shareReplay } from 'rxjs';
import { LoadingService } from '../../services/loading.service';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../../utils/injection-tokens';
import { AuthService } from '../../services/auth.service';
import { AdminService } from '../../services/api/admin.service';
import { UserRoles } from '../../models/user-roles';
import { OrganisationResponse } from '../../models/organisation-response';
import { Garages } from '../../models/garages';
import { GarageService } from '../../services/api/garage.service';

@Component({
  selector: 'tbums-lib-user-menu',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatTooltipModule, MatIconModule, MatListModule],
  template: ` <button
      mat-flat-button
      [matMenuTriggerFor]="userMenu"
      [matTooltip]="userName.length > 22 ? userName : ''"
      color="primary"
      class="hidden sm:flex"
      aria-label="Account-button with a menu"
    >
      <mat-icon>account_circle</mat-icon>
      <span class="block max-w-[180px] overflow-hidden overflow-ellipsis">{{ userName }}</span>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="userMenu"
      class="block sm:hidden"
      aria-label="Account-button with a menu"
    >
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
      <mat-list (click)="$event.stopPropagation()">
        <mat-list-item class="text-lg font-bold sm:hidden">
          <h2 matListItemTitle>{{ userName }}</h2>
        </mat-list-item>
        <mat-list-item>
          <span matListItemTitle class="pb-0.5">{{ translation.toolbar.userMenu.subheading1 }}</span>
          <span *ngFor="let role of roles; index as i" matListItemLine>{{ role }}</span>
        </mat-list-item>
        <mat-list-item>
          <span matListItemTitle>{{ translation.toolbar.userMenu.subheading2 }}</span>
          <span matListItemLine>{{ organisations }}</span>
        </mat-list-item>
      </mat-list>

      @if (userGarageId$ | async; as garageId) {
        <a mat-menu-item href="/garage/{{ garageId }}/users">
          <mat-icon>directions_bus</mat-icon>
          <span>{{ translation.toolbar.userMenu.manageGarage }}</span>
        </a>
      }

      <a mat-menu-item href="/usersettings" *ngIf="showChooseOperator$ | async">
        <mat-icon>group</mat-icon>
        <span>{{ translation.toolbar.userMenu.operatorBtn }}</span>
      </a>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>{{ translation.toolbar.userMenu.logout }}</span>
      </button>
    </mat-menu>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit {
  userName = '';
  user!: AccountInfo;
  roles: string[] = [];
  organisations = '-';
  translation: LibTranslation = inject<LibTranslation>(libTranslationConfigToken);
  showChooseOperator$ = new BehaviorSubject<boolean>(false);
  userGarageId$!: Observable<number>;

  private _authService: AuthService = inject(AuthService);
  private _adminService: AdminService = inject(AdminService);
  private _loadingService: LoadingService = inject(LoadingService);
  private _garageService: GarageService = inject(GarageService);

  private _destryRef = inject(DestroyRef);

  ngOnInit() {
    this._authService.user$.pipe(takeUntilDestroyed(this._destryRef)).subscribe((user) => {
      if (user) {
        this.setUserProperties(user);
      }
    });

    this.userGarageId$ = this._garageService.getGarageState().pipe(
      filter((garages: Garages) => garages.data.length > 0),
      map((garages: Garages) => garages.data[0].id),
      shareReplay(1),
    );
  }

  logout() {
    this._authService.logout();
  }

  setUserProperties(user: AccountInfo | null) {
    if (user) {
      this.user = user;
      if (
        this.user?.idTokenClaims?.['given_name'] !== null &&
        this.user?.idTokenClaims?.['given_name'] !== undefined &&
        this.user?.idTokenClaims?.['family_name'] !== null &&
        this.user?.idTokenClaims?.['family_name'] !== undefined
      ) {
        const fullName = this.user?.idTokenClaims?.['given_name'] + ' ' + this.user?.idTokenClaims?.['family_name'];
        this.userName = fullName;
      } else this.userName = this.user?.idTokenClaims?.name ?? '';
    }
    this.setUserRoles();
    this.getMyOrganization();
  }

  setUserRoles(): void {
    const tempRoles: string[] = [];
    if (this.user?.idTokenClaims && this.user?.idTokenClaims['role']) {
      for (const role in UserRoles) {
        if ((this.user.idTokenClaims['role'] as string[]).includes(role)) {
          tempRoles.push(UserRoles[role].toString());
        }
      }
      this.roles = tempRoles;

      this.showChooseOperator$.next(
        this._authService.userHasAnyRole([
          UserRoles.NobinaTrafikledare,
          UserRoles.NobinaLeveransansvarig,
          UserRoles.OperatorTrafikledare,
        ]),
      );
    }
  }

  getMyOrganization() {
    this._loadingService
      .runWithLoader(this._adminService.getMyOrganization$())
      .pipe(takeUntilDestroyed(this._destryRef))
      .subscribe({
        next: (response: OrganisationResponse) => {
          this.organisations = response.organization ?? '-';
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
