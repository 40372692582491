import { Language, TravelerTranslationModel } from '../models/translation.model';

export const translationSV: TravelerTranslationModel = {
  language: Language.SV,
  searchPlaceholder: 'Sök hållplats',
  today: 'Idag',
  tomorrow: 'Imorgon',
  stop: {
    route: 'Linje',
    replacedTrain: 'Ersätter Tåg',
    towards: 'Mot',
    departs: 'Avgår',
    stop: 'Hållplats',
    location: 'Plats',
    active: 'Aktiv',
    reason: 'Orsak',
    vehicleIdentifier: 'Fordon',
  },
  info: {
    about: 'Om',
    content:
      'Denna webbplats visar information om ersättningstrafik som körs av Nobina på uppdrag av ditt tågbolag. Målet är att du som resenär ska få relevant information om pågående ersättningstrafik, oavsett om den är planerad eller akut. Sidan visar all bekräftad trafik för de kommande 24 timmarna. All information du kan finna på denna sida finns även fullt tillgänglig via API för integration till reseappar, reseplanerare och andra tjänster. Vi är Nobina, Nordens största kollektivtrafikföretag. Hos oss jobbar 13 000 människor varje dag för att hitta lösningar på ditt vardagspussel och på samhällets stora utmaningar.',
    close: 'Stäng',
    readMore: 'Läs mer på nobina.com',
  },
  error: {
    affectedStopsMissing: 'Det finns ingen ersättningstrafik för tillfället',
    stopMissing: 'Information för hållplatsen saknas',
    tripMissing: 'Information för turen saknas',
    close: 'Stäng',
  },
  seo: {
    tagline: 'Var är min ersättningsbuss?',
    description:
      'Ersättningstrafik som körs av Nobina på uppdrag av ditt tågbolag. Målet är att du som resenär ska få relevant information om pågående ersättningstrafik.',
  },
};
