import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { NotificationComponent } from '../notification/notification.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationMessage } from '../../../models/notification-message';
import { libTranslationConfigToken } from '../../../utils/injection-tokens';
import { LibTranslation } from '../../../i18n/lib-translation.model';
import { LoadingService } from '../../../services/loading.service';
import { NotificationService } from '../../../services/api/notification.service';

@Component({
  selector: 'tbums-lib-notifications',
  standalone: true,
  imports: [CommonModule, MatButtonModule, NotificationComponent, MatDialogModule, MatListModule],
  template: ` <div mat-dialog-title class="flex items-baseline justify-between overflow-hidden px-6 pb-2">
      <h2 class="mr-auto">
        {{ translation.toolbar.notifications.title }}
      </h2>
      <button *ngIf="numberOfUnreadNotifications" mat-button color="accent" class="text-sm" (click)="markAllRead()">
        {{ translation.toolbar.notifications.markAsRead }}
      </button>
    </div>
    <mat-list matDialogContent class="p-0">
      @if (notifications) {
        @for (notification of notifications.slice(0, 8); track notification.id) {
          <tbums-lib-notification [notification]="notification" />
          <hr />
        }
      }

      <mat-list-item *ngIf="!notifications?.[0]"
        >{{ translation.toolbar.notifications.noNotifications }}
      </mat-list-item>
    </mat-list>
    <div *ngIf="notifications?.[0]" mat-dialog-actions class="flex">
      <button mat-dialog-close="" mat-button color="warn" class="grow sm:hidden">
        {{ translation.common.button.close }}
      </button>
      <a mat-button color="primary" href="/notifications" class="ml-0 grow">
        {{ translation.toolbar.notifications.seeAll }}
      </a>
    </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  @Input() notifications: NotificationMessage[] = [];
  @Input() numberOfUnreadNotifications!: number;
  translation: LibTranslation = inject<LibTranslation>(libTranslationConfigToken);

  private _destryRef = inject(DestroyRef);
  private _loadingService: LoadingService = inject(LoadingService);
  private _notificationService: NotificationService = inject(NotificationService);

  markAllRead() {
    const unreadNotifications: NotificationMessage[] = this.notifications.filter(
      (notification: NotificationMessage) => !notification.isRead,
    );
    const unreadNotificationsIds: number[] = unreadNotifications.map(
      (notification: NotificationMessage) => notification.id,
    );
    this._loadingService
      .runWithLoader<unknown>(this._notificationService.setNotificationRead(unreadNotificationsIds))
      .pipe(takeUntilDestroyed(this._destryRef))
      .subscribe(() => {
        this._notificationService.getNotifications();
      });
  }
}
