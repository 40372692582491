import { inject, Injectable } from '@angular/core';
import { libTranslationConfigToken } from '../utils/injection-tokens';
import { LibTranslation } from '../i18n/lib-translation.model';

@Injectable({
  providedIn: 'root',
})
export class CancelReasonUtilService {
  translation = inject<LibTranslation>(libTranslationConfigToken);
  allReasons = new Map<number, string>();
  selectableReasons = new Map<number, string>();

  constructor() {
    this.allReasons.set(1, this.translation.order.trip.cancelReason1);
    this.allReasons.set(2, this.translation.order.trip.cancelReason2);
    this.allReasons.set(3, this.translation.order.trip.cancelReason3);
    this.allReasons.set(4, this.translation.order.trip.cancelReason4);
    this.allReasons.set(5, this.translation.order.trip.cancelReason5);
    this.allReasons.set(6, this.translation.order.trip.cancelReason6);
    this.allReasons.set(7, this.translation.order.trip.cancelReason7);

    this.selectableReasons.set(1, this.translation.order.trip.cancelReason1);
    this.selectableReasons.set(2, this.translation.order.trip.cancelReason2);
    this.selectableReasons.set(3, this.translation.order.trip.cancelReason3);
    this.selectableReasons.set(4, this.translation.order.trip.cancelReason4);
    this.selectableReasons.set(5, this.translation.order.trip.cancelReason5);
    this.selectableReasons.set(6, this.translation.order.trip.cancelReason6);
  }

  getCancellationReason(reasonEnum: number): string {
    return this.allReasons.get(reasonEnum) ?? '';
  }
}
