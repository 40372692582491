export const customTailwindColors = {
  primary: 'rgb(var(--color-primary) / <alpha-value>)',
  secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
  success: 'rgb(var(--color-success) / <alpha-value>)',
  alert: 'rgb(var(--color-alert) / <alpha-value>)',
  warn: 'rgb(var(--color-warn) / <alpha-value>)',
  info: 'rgb(var(--color-info) / <alpha-value>)',
  green1: '#C4DB9B',
  green2: '#9FCA79',
  green3: '#62B55A',
  green4: '#008E38',
  green5: '#006F32',
  green6: '#125428',
  orange1: '#FDC543',
  orange2: '#F28A3D',
  red1: '#EB5D48',
  red2: '#D73534',
  blue1: '#B1D6E4',
  blue2: '#67B7D1',
  blue3: '#2D81B1',
  blue4: '#00508C',
  pink1: '#F8C9DF',
  pink2: '#F29FC5',
  pink3: '#CB5D9F',
  pink4: '#B9348B',
  gray1: '#7d7d7d',
  gray2: '#dfdfdf',
};

export const customTailwindScreens = {
  /* eslint-disable @typescript-eslint/naming-convention */
  '3xl': '1792px',
  '4xl': '1920px',
  '5xl': '2560px',
  /* eslint-enable @typescript-eslint/naming-convention */
};
