import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { OrderSearch } from '../../models/order-search';
import { Order, OrderWithQueryParm } from '../../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  readonly baseUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(appConfigToken) private _appConfig: ConfigModelWeb,
    @Inject(libTranslationConfigToken) private _translation: LibTranslation,
  ) {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this.baseUrl = apiUrl + apiVersionMain + '/orders';
  }

  /** GET orders from the server */
  getOrders$(search: OrderSearch): Observable<OrderWithQueryParm[]> {
    const { orderTypes, orderStates, orderIds, referenceIds, driveOrderIds, externalIds, tripIds, dateRange } = search;
    let url = this.baseUrl;
    url += `?OrderTypes=${orderTypes.toString()}`;
    url += `&OrderStates=${orderStates.toString()}`;
    url += `&OrderIds=${orderIds.toString()}`;
    url += `&ReferenceIds=${referenceIds.toString()}`;
    url += `&DriveOrderIds=${driveOrderIds.toString()}`;
    url += `&ExternalIds=${externalIds.toString()}`;
    url += `&TripIds=${tripIds.toString()}`;
    if (dateRange !== undefined) {
      const { fromDate, toDate } = dateRange;
      const dateFormat = 'yyyy-MM-ddTHH:mm:ss.SSSz';
      const from = format(fromDate, dateFormat);
      const to = format(toDate, dateFormat);
      url += '&StartDate=' + encodeURIComponent(from);
      url += '&EndDate=' + encodeURIComponent(to);
    }
    return this._http.get<OrderWithQueryParm[]>(url);
  }

  getOrder(orderId: string) {
    const url = `${this.baseUrl}/${orderId}`;
    return this._http.get<Order>(url);
  }
}
