import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { A11yModule } from '@angular/cdk/a11y';
import { Alert, AlertType } from '../../../models/alert';
import { libTranslationConfigToken } from '../../../utils/injection-tokens';
import { LibTranslation } from '../../../i18n/lib-translation.model';
import { format } from 'date-fns';

@Component({
  selector: 'tbums-lib-snackbar',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, A11yModule],
  template: ` <div class="flex items-center justify-between">
    <mat-icon
      class="mr-2 min-w-[24px]"
      [ngClass]="{
        'text-success': data.type === alertType.Success,
        'text-warn': data.type === alertType.Error,
        'text-info': data.type === alertType.Info,
        'text-alert': data.type === alertType.Warning
      }"
    >
      {{ data.type === alertType.Success ? 'check_circle' : '' }}
      {{ data.type === alertType.Error ? 'error' : '' }}
      {{ data.type === alertType.Info ? 'info' : '' }}
      {{ data.type === alertType.Warning ? 'warning' : '' }}
    </mat-icon>
    <div class="flex flex-col">
      <span> {{ data.message }}</span>
      @if (data.operationId) {
        <span class="text-gray-400">
          {{ date }} {{ translation.api.error.errorId }}: {{ data.operationId.substring(0, 10) }}</span
        >
      }
    </div>
    <span>
      <button
        mat-button
        class="text-success"
        [ngClass]="{
          'text-success': data.type === alertType.Success,
          'text-warn': data.type === alertType.Error,
          'text-info': data.type === alertType.Info,
          'text-alert': data.type === alertType.Warning
        }"
        cdkFocusInitial
        (click)="snackBarRef.dismissWithAction()"
      >
        Stäng
      </button>
    </span>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);
  data: Alert = inject(MAT_SNACK_BAR_DATA);
  translation = inject<LibTranslation>(libTranslationConfigToken);

  date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  protected readonly alertType = AlertType;
}
