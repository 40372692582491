import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { RedirectDialogModel } from './redirect-dialog.model';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../../utils/injection-tokens';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tbums-lib-redirect-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, RouterModule],
  template: `
    <div class="rounded w-full h-full flex flex-row items-center justify-between p-4 border-green3 border-b-2">
      <div class="bg-green3 rounded-full w-2 h-2 mr-4"></div>
      <div class="flex-1">
        <div class="text-xs text-gray1">{{ data.title }}</div>
        <div class="text-sm">{{ data.message }}</div>
      </div>
      <a
        mat-button
        [href]="data.link"
        *ngIf="data.linkExternal"
        class="inline-block w-full rounded-md p-2 text-left text-black no-underline hover:bg-zinc-100 sm:w-auto md:block"
        routerLinkActive="active"
        #rla="routerLinkActive"
        ><span class="text-green3">{{ translation.toolbar.notifications.goTo }}</span></a
      >
      <a
        mat-button
        *ngIf="!data.linkExternal"
        [routerLink]="data.link"
        routerLinkActive="md:underline underline-offset-[6px] md:underline-offset-[12px]"
        class=" inline-block w-full rounded-md p-2 text-left text-black hover:bg-zinc-100 sm:w-auto md:block"
        #rla="routerLinkActive"
      >
        <span class="text-green3">{{ translation.toolbar.notifications.goTo }}</span>
      </a>

      <button mat-icon-button (click)="close()">
        <mat-icon class="text-green3">close</mat-icon>
      </button>
    </div>
    <!--<mat-dialog-content>{{ data.message }}</mat-dialog-content>-->
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectDialogComponent {
  data = inject<RedirectDialogModel>(MAT_DIALOG_DATA);
  translation = inject<LibTranslation>(libTranslationConfigToken);
  dialogRef = inject(MatDialogRef);

  close() {
    this.dialogRef.close();
  }
}
