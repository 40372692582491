import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidePanelModel } from './side-panel.model';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  private _sidePanelData$: BehaviorSubject<SidePanelModel | null> = new BehaviorSubject<SidePanelModel | null>(null);

  get sidePanelData$(): Observable<SidePanelModel | null> {
    return this._sidePanelData$.asObservable();
  }

  setSidePanelData(data: SidePanelModel | null) {
    this._sidePanelData$.next(data);
  }
}
