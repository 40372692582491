import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';

@Component({
  selector: 'tbums-lib-alert',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
  private _alertService: AlertService = inject(AlertService);
  private _snackBar: MatSnackBar = inject(MatSnackBar);

  ngOnInit(): void {
    this._alertService.alert$.subscribe((alert) => {
      this.openSnackbar(alert);
    });
  }

  openSnackbar(alert: Alert): void {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: alert.duration ?? 8000,
      data: alert,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
