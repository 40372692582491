import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { ConfigModelWeb, EnvironmentType } from '../assets/config/config.model';

export function msalInstanceFactory(appConfig: ConfigModelWeb): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: appConfig.oauth.clientId,
      authority: appConfig.oauth.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: appConfig.oauth.redirectUrl,
      knownAuthorities: [appConfig.oauth.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => {
          if (appConfig.environmentType !== EnvironmentType.Production) {
            console.log(message);
          }
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function msalGuardConfigFactory(appConfig: ConfigModelWeb): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      extraQueryParameters: {
        countryCode: appConfig.region.language === 'sv' ? 'SE' : 'NO',
        theme: 'light',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ui_locales: appConfig.region.language,
      },
      scopes: appConfig.oauth.garageServiceScopes,
      state: window.location.pathname,
    },
    loginFailedRoute: '/login-failed',
  };
}

export function msalInterceptorConfigFactory(appConfig: ConfigModelWeb): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap
    .set(appConfig.apiUrlConfig.apiUrl, appConfig.oauth.mainServiceScopes)
    .set(appConfig.apiUrlConfig.garageUrl, appConfig.oauth.garageServiceScopes)
    .set(appConfig.apiUrlConfig.routeUrl, appConfig.oauth.routeServiceScopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap,
    authRequest: {
      extraQueryParameters: {
        countryCode: appConfig.region.language === 'sv' ? 'SE' : 'NO',
        theme: 'light',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ui_locales: appConfig.region.language,
      },
    },
  };
}
