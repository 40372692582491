import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { UserRoles } from '../models/user-roles';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$: BehaviorSubject<AccountInfo | null> = new BehaviorSubject<AccountInfo | null>(null);

  private _msalService: MsalService = inject(MsalService);
  private _msalBroadcastService: MsalBroadcastService = inject(MsalBroadcastService);

  get user$() {
    return this._user$.pipe(filter((user) => !!user));
  }

  get user() {
    return this._user$.value;
  }

  get userName(): string {
    const firstName = this._user$.value?.idTokenClaims?.['given_name'] ?? '';
    const lastName = this._user$.value?.idTokenClaims?.['family_name'] ?? '';
    return firstName + ' ' + lastName;
  }

  getSubcontractorId() {
    if (this._user$.value?.idTokenClaims?.['garageIds']) {
      return (this._user$.value?.idTokenClaims?.['garageIds'] as string[])[0] ?? '';
    }

    return '';
  }

  initialize() {
    this._msalService.handleRedirectObservable().subscribe();

    this._msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) {
        const account = event.payload as AuthenticationResult;
        this._msalService.instance.setActiveAccount(account.account);
      } else if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        this._msalService.logout();
      }
    });

    this._msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    const activeAccount = this._msalService.instance.getActiveAccount();
    const accounts = this._msalService.instance.getAllAccounts();

    if (activeAccount) {
      this.setActiveUser(activeAccount);
    } else if (!activeAccount && accounts.length === 1 && accounts[0].idTokenClaims) {
      this._msalService.instance.setActiveAccount(accounts[0]);
      this.setActiveUser(accounts[0]);
    } else if (accounts.length > 1) {
      this._msalService.loginRedirect();
    } else {
      this.setActiveUser(null);
    }
  }

  setActiveUser(account: AccountInfo | null) {
    this._user$.next(account);
  }

  logout() {
    this._msalService.logout();
  }

  userHasAnyRole(roles: UserRoles[]): boolean {
    if (this._user$.value?.idTokenClaims?.['role']) {
      const claimsRoles = this._user$.value.idTokenClaims?.['role'] as string[];
      let containsRole = false;
      roles.forEach((role) => {
        if (claimsRoles.includes(role.toString())) {
          containsRole = true;
        }
      });
      return containsRole;
    } else {
      return false;
    }
  }
}
