import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  NgZone,
  HostListener
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[heightObserver]'
})
export class HeightObserverDirective implements AfterViewInit, OnDestroy {
  @Output() heightChange = new EventEmitter<number>();

  private resizeObserver!: ResizeObserver;
  private height = 0;
  constructor(private el: ElementRef, private zone: NgZone) {}

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.heightChange.emit(this.height);
  }
  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.zone.run(() => {
          const newHeight = entry.contentRect.height;
          this.height = newHeight;
          this.heightChange.emit(newHeight);
        });
      }
    });

    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
