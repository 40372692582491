import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksModel } from '../navigation/links.model';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tbums-lib-tab-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, MatButtonModule, MatIconModule],
  template: ` <div class="mb-6 flex min-w-[320px] md:min-w-full flex-row rounded-md border md:border-none">
    <div class="flex flex-1 flex-col justify-self-start text-base md:flex-row md:gap-3">
      <ng-container *ngFor="let link of links; index as i">
        <a
          [href]="link.address"
          *ngIf="link.isExternal"
          class=" inline-block w-full rounded-md p-2 text-left text-black no-underline hover:bg-zinc-100 sm:w-auto md:block"
          routerLinkActive="active"
          #rla="routerLinkActive"
          [ngClass]="{
            hidden: !isMenuVisible() && !rla.isActive,
            underline: isMenuVisible() && rla.isActive
          }"
          >{{ link.label }}</a
        >
        <a
          *ngIf="!link.isExternal"
          [routerLink]="link.address"
          routerLinkActive="md:underline underline-offset-[6px] md:underline-offset-[12px]"
          class=" inline-block w-full rounded-md p-2 text-left text-black hover:bg-zinc-100 sm:w-auto md:block"
          #rla="routerLinkActive"
          [ngClass]="{
            hidden: !isMenuVisible() && !rla.isActive,
            underline: isMenuVisible() && rla.isActive
          }"
          >{{ link.label }}</a
        >
      </ng-container>
    </div>
    <div class="flex-none justify-self-end">
      <button mat-button class="visible m-auto h-10 w-10 p-2 md:hidden" (click)="isMenuVisible.set(!isMenuVisible())">
        <mat-icon class="m-auto" *ngIf="!isMenuVisible()">menu</mat-icon>
        <mat-icon class="m-auto" *ngIf="isMenuVisible()">close</mat-icon>
      </button>
    </div>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavComponent {
  @Input() links!: LinksModel[];
  isMenuVisible = signal(false);
}
