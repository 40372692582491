export interface TravelerTranslationModel {
  language: Language;
  searchPlaceholder: string;
  today: string;
  tomorrow: string;
  stop: {
    route: string;
    replacedTrain: string;
    towards: string;
    departs: string;
    stop: string;
    location: string;
    active: string;
    reason: string;
    vehicleIdentifier: string;
  };
  info: {
    about: string;
    content: string;
    readMore: string;
    close: string;
  };
  error: {
    affectedStopsMissing: string;
    stopMissing: string;
    tripMissing: string;
    close: string;
  };
  seo: {
    tagline: string;
    description: string;
  };
}

export enum Language {
  SV = 'sv',
  EN = 'en',
  NB = 'nb',
  FI = 'fi',
  DK = 'dk',
}
