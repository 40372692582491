import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksModel } from './links.model';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'tbums-lib-navigation',
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterLink, RouterLinkActive],
  template: ` <div class="flex flex-col lg:flex-row">
    <ng-container *ngFor="let link of links; index as i">
      <div class="transparent-buttons">
        <a
          [href]="link.address"
          *ngIf="link.isExternal"
          mat-flat-button
          color="primary"
          class="w-full no-underline sm:w-auto"
          routerLinkActive="active"
          [class.active]="i === 1"
          >{{ link.label }}</a
        >
        <a
          *ngIf="!link.isExternal"
          [routerLink]="link.address"
          mat-flat-button
          class="w-full no-underline sm:w-auto"
          color="primary"
          >{{ link.label }}</a
        >
      </div>
    </ng-container>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() links!: LinksModel[];
}
