import { inject, Injectable } from '@angular/core';
import { appConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PlanningOrder } from '../../models/planning-order';
import { Observable } from 'rxjs';
import { format, set } from 'date-fns';
import { PlanningDriveOrders } from '../../models/planning-drive-orders';
import { DriveOrderStatus } from '../../models/drive-order-status';

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  readonly baseUrl: string;
  private _appConfig: ConfigModelWeb = inject<ConfigModelWeb>(appConfigToken);
  private _httpClient = inject(HttpClient);

  constructor() {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this.baseUrl = apiUrl + apiVersionMain + '/planning';
  }

  getOrders$(
    startDate: Date,
    endDate: Date,
    orderTypes: string,
    includeCancelledOrders: boolean,
  ): Observable<PlanningOrder[]> {
    const startDateString = format(startDate, 'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX');
    const endDateString = format(endDate, 'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX');
    const querystring = `${this.baseUrl}/orders?StartDateTime=${encodeURIComponent(
      startDateString,
    )}&EndDateTime=${encodeURIComponent(endDateString)}&OrderTypes=${orderTypes}&IncludeCancelledOrders=${includeCancelledOrders}`;
    return this._httpClient.get<PlanningOrder[]>(querystring);
  }

  getDriveOrders$(
    startDate: Date,
    endDate: Date,
    orderId?: string,
    externalId?: string,
    status?: number,
    subcontractors?: number[],
    page?: number,
    pageSize?: number,
    sortColumn?: number,
    sortAsc?: boolean,
  ): Observable<PlanningDriveOrders> {
    const startDateString = format(
      set(startDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX',
    );
    const endDateString = format(
      set(endDate, { hours: 23, minutes: 59, seconds: 59 }),
      'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX',
    );
    let queryString = `${this.baseUrl}/drive-order?StartDateTime=${encodeURIComponent(
      startDateString,
    )}&EndDateTime=${encodeURIComponent(endDateString)}`;

    if (orderId) {
      queryString += `&OrderId=${orderId}`;
    }

    if (externalId) {
      queryString += `&ExternalId=${externalId}`;
    }

    if (status) {
      queryString += `&AssignmentState=${status}`;
    }

    if (subcontractors && subcontractors.length > 0) {
      queryString += `&SubcontractorIds=${subcontractors.join(',')}`;
    }

    if (page) {
      queryString += `&Page=${page}`;
    }

    if (pageSize) {
      queryString += `&PageSize=${pageSize}`;
    }

    if (sortColumn) {
      queryString += `&SortKey=${sortColumn}&SortAsc=${sortAsc}`;
    }

    return this._httpClient.get<PlanningDriveOrders>(queryString);
  }

  getDriveOrderInquiries$(driveOrderId: number): Observable<DriveOrderStatus> {
    const url = `${this.baseUrl}/drive-order/${driveOrderId}`;
    return this._httpClient.get<DriveOrderStatus>(url);
  }

  createInquiry$(driveOrderIds: number[], subcontractorIds: number[], comment?: string): Observable<void> {
    return this._httpClient.post<void>(`${this.baseUrl}/inquiry`, {
      driveOrderIds: driveOrderIds,
      subcontractorIds: subcontractorIds,
      creationComment: comment,
    });
  }

  replyInquiry$(
    subcontractorId: number,
    driveOrderIds: number[],
    response: number,
    comment?: string,
  ): Observable<void> {
    return this._httpClient.post<void>(`${this.baseUrl}/inquiry/reply`, {
      driveOrderIds: driveOrderIds,
      subcontractorId: subcontractorId,
      response: response,
      responseComment: comment,
    });
  }

  assignDriveOrders$(driveOrderIds: number[], subcontractorId: number): Observable<void> {
    return this._httpClient.post<void>(`${this.baseUrl}/drive-orders/assign`, {
      driveOrderIds: driveOrderIds,
      subcontractorId: subcontractorId,
    });
  }

  createDriveOrdersReport$(
    startDate: Date,
    endDate: Date,
    orderId?: string,
    externalId?: string,
    subcontractors?: number[],
    sortColumn?: number,
    sortAsc?: boolean,
    driveOrderIds?: number[],
    offset?: string,
  ): Observable<HttpResponse<Blob>> {
    const startDateString = format(
      set(startDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX',
    );
    const endDateString = format(
      set(endDate, { hours: 23, minutes: 59, seconds: 59 }),
      'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX',
    );
    let queryString = `${this.baseUrl}/drive-orders/report?StartDateTime=${encodeURIComponent(
      startDateString,
    )}&EndDateTime=${encodeURIComponent(endDateString)}`;

    if (orderId) {
      queryString += `&OrderId=${orderId}`;
    }

    if (externalId) {
      queryString += `&ExternalId=${externalId}`;
    }

    if (subcontractors && subcontractors.length > 0) {
      queryString += `&SubcontractorIds=${subcontractors.join(',')}`;
    }

    if (sortColumn) {
      queryString += `&SortKey=${sortColumn}&SortAsc=${sortAsc}`;
    }

    if (driveOrderIds && driveOrderIds.length > 0) {
      queryString += `&DriveOrderIds=${driveOrderIds.join(',')}`;
    }

    if (offset) {
      queryString += `&Offset=${offset}`;
    }

    return this._httpClient.get(queryString, { responseType: 'blob', observe: 'response' });
  }
}
