import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loadingMap: Map<string, boolean> = new Map<string, boolean>();

  private _loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get loadingSub$(): Observable<boolean> {
    return this._loadingSub.asObservable();
  }

  runWithLoader<T>(service$: Observable<T>): Observable<T> {
    const id = uuid();
    return of(true).pipe(
      tap(() => this._setLoading(true, id)),
      switchMap(() =>
        service$.pipe(
          catchError((err) => {
            this._setLoading(false, id);
            return throwError(() => err);
          }),
        ),
      ),
      tap(() => this._setLoading(false, id)),
    );
  }

  private _setLoading(loading: boolean, id: string): void {
    if (loading) {
      this._loadingMap.set(id, loading);
      this._loadingSub.next(true);
    } else if (!loading && this._loadingMap.has(id)) {
      this._loadingMap.delete(id);
    }
    if (this._loadingMap.size === 0) {
      this._loadingSub.next(false);
    }
  }
}
