import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })
export class AppService {
  private _sidenav$ = new Subject<string>();

  get sidenav$() {
    return this._sidenav$.asObservable();
  }

  toggleSidenav() {
    this._sidenav$.next(uuid());
  }
}
