import { LibTranslation } from './lib-translation.model';

export const libTranslationFI: LibTranslation = {
  common: {
    errorMessage: 'On tapahtunut virhe',
    button: {
      close: 'Sulje',
      cancel: 'Peruuta',
      apply: 'Käytä',
      assign: 'Määrää',
      save: 'Tallenna',
      confirm: 'Vahvista',
    },
  },
  toolbar: {
    navigation: {
      order: 'Tilaukset',
      history: 'Historia',
      home: 'Koti',
      outcome: 'Tulosseuranta',
      planning: 'Suunnittelu',
      administration: 'Hallinto',
      routes: 'Rivit',
    },
    userMenu: {
      operatorBtn: 'Valitse operaattori',
      subheading1: 'Roolini:',
      subheading2: 'Organisaationi:',
      title: 'Profiili',
      logout: 'Kirjaudu ulos',
      manageGarage: 'Hallinnoi parkkiani',
    },
    notifications: {
      title: 'Ilmoitukset',
      markAsRead: 'Merkitse luetuksi',
      seeAll: 'Näytä kaikki',
      noNotifications: 'Ei ilmoituksia',
      goTo: 'Siirry',
      alert: {
        openDriveOrder: 'Avaa ajo-ohje',
        reconnecting: 'Yhteys reaaliaikapäivityksiin on katkennut. Yritetään uudelleen yhdistää palvelimeen.',
        reconnected: 'Yhteys palvelimeen palautettu.',
      },
      driveOrderInquiryCreatedNotification: {
        title: 'Uusi saapuva pyyntö',
        severalDriveOrdersMessage: 'Sinulla on {0} uutta pyyntöä',
        oneDriveOrderMessage: 'Sinulla on {0} uusi pyyntö',
      },
      driveOrderAssignedNotification: {
        title: 'Uusi osoitus',
        severalDriveOrdersMessage: 'Sinulle on osoitettu {0} uutta kuljetusta',
        oneDriveOrderMessage: 'Sinulle on osoitettu {0} uusi kuljetus',
      },
    },
    search: {
      select: {
        orderId: 'Tilausnumero',
        driveOrderId: 'Ajo-ohje',
        tripId: 'Matka',
        unboundOrderId: 'Vapaa tilaus',
      },
      alertMsg: {
        notANumberMessage: 'Virheellinen numero. Anna vain numeroita.',
        notFoundMessage: 'Järjestelmässä ei ole tilausta, joka vastaa annettua numeroa.',
      },
    },
  },
  vehicleTypes: {
    bigBus: 'Iso linja-auto',
    smallBus: 'Pieni linja-auto',
    doubleDecker: 'Kaksikerroksinen',
    taxi: 'Taksi',
    bigTaxi: 'Iso taksi',
    wheelchairTaxi: 'Pyörätuolitaksi',
    standbyBus: 'Valmiuslinja-auto',
    standbyWheelchairTaxi: 'Valmiuspyörätuolitaksi',
    customerHost: 'Asiakasemäntä',
    busCoordinator: 'Bussikoordinaattori',
    car: 'Henkilöauto',
  },
  order: {
    trip: {
      cancelReason1: 'Virheellinen tilaus',
      cancelReason2: 'Peruttu tilaajan toimesta',
      cancelReason3: 'Tilauksen muutos',
      cancelReason4: 'Ei toteutettu',
      cancelReason5: 'Suorittamatta jäänyt',
      cancelReason6: 'Muut syyt',
      cancelReason7: 'Korvattu',
    },
    tripStatus: {
      upcoming: 'Tuleva',
      ongoing: 'Käynnissä',
      completed: 'Päättynyt',
      cancelled: 'Peruttu',
    },
    orderType: {
      urgent: 'Kiireellinen',
      unplanned: 'Suunnittelematon',
      planned: 'Suunniteltu',
      unbound: 'Vapaa tilaus',
    },
  },
  search: {
    filter: {
      startDateTime: 'aloituspäivämäärä',
      endDateTime: 'lopetuspäivämäärä',
      hours: 'Tuntia',
      minutes: 'Minuuttia',
      resetTime: 'Nollaa aika',
    },
  },
  api: {
    error: {
      forbidden: 'Ei lupaa (tila {0})',
      noConnection: 'Yhteyttä palvelimeen ei ole',
      unauthorized: 'Ei lupaa (tila {0})',
      unknown: 'Tuntematon virhe',
      errorId: 'Virhe-ID',
    },
    success: {
      toggleGarageSuccess: 'Garagen tila muutettu',
      createUserSuccess: 'Käyttäjä luotu',
      deleteUserSuccess: 'Käyttäjä poistettu',
      deleteUsersSuccess: 'Käyttäjät poistettu',
      updateUserSuccess: 'Käyttäjä päivitetty',
      resetPasswordSuccess: 'Salasana palautettu',
      assignSubcontractorToDriveOrderSuccess: 'Aliurakoitsija määritelty',
      assignVehicleToDriveOrderSuccess: 'Ajoneuvo määritelty',
      assignDriverToDriveOrderSuccess: 'Kuljettaja määritelty',
      changeStartTimeSuccess: 'Aloitusaika muutettu',
      saveTrainNumberSuccess: 'Junanumero tallennettu',
      savePassengerCountSuccess: 'Matkustajien määrä tallennettu',
      saveCommentSuccess: 'Kommentti tallennettu',
      cancelTripsSuccess: 'Matkat peruttu',
      splitTripsSuccess: 'Matkat jaettu',
      duplicateTripsSuccess: 'Matkat kopioitu',
      replaceTripsSuccess: 'Matkat korvattu',
      changeVehicleTypeSuccess: 'Fordonstyp har ändrats',
    },
  },
};
