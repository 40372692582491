import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Garages } from '../../models/garages';
import { Observable } from 'rxjs';
import { appConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { User } from '../../models/user';
import { UserApi } from '../../models/user-api';
import { SubcontractorList } from '../../models/subcontractor-list';
import { GarageVehicle } from '../../models/garage-vehicle';

@Injectable({
  providedIn: 'root',
})
export class GarageService {
  private readonly _baseUrl: string;
  private _http = inject(HttpClient);
  private _appConfig = inject<ConfigModelWeb>(appConfigToken);
  private _garageBaseUrl = this._appConfig.apiUrlConfig.garageUrl + this._appConfig.apiUrlConfig.apiVersionGarage;

  constructor() {
    const { garageUrl, apiVersionGarage } = this._appConfig.apiUrlConfig;
    this._baseUrl = garageUrl + apiVersionGarage;
  }

  getGarageState(): Observable<Garages> {
    const url = `${this._garageBaseUrl}/subcontractors`;
    return this._http.get<Garages>(url);
  }

  getRoles$(): Observable<string[]> {
    const url = `${this._baseUrl}/roles`;
    return this._http.get<string[]>(url);
  }

  getUsers$(
    subcontractorId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn?: string,
    sortDirection?: string,
    filter?: string,
  ): Observable<UserApi> {
    const href = `${this._baseUrl}/users`;

    const subcontractorParam = `?subcontractorId=${subcontractorId}`;
    const pageParam = `&offset=${pageIndex * pageSize}&limit=${pageSize}`;
    const sortParam = sortDirection ? `&sort=${sortColumn}.${sortDirection}` : '';
    const searchParam = filter ? `&search=${filter ?? ''}` : '';

    const requestUrl = `${href}${subcontractorParam}${pageParam}${sortParam}${searchParam}`;

    return this._http.get<UserApi>(requestUrl);
  }

  createUser$(subcontractorId: number, user: User): Observable<string> {
    user.subcontractorId = subcontractorId;
    const requestUrl = `${this._baseUrl}/users`;
    return this._http.post<string>(requestUrl, user);
  }

  updateUser$(user: User): Observable<string> {
    let requestUrl = `${this._baseUrl}/users/`;
    requestUrl += user.id;
    return this._http.put<string>(requestUrl, user);
  }

  deleteUsers$(users: User[]): Observable<string> {
    const ids = users.map((user) => 'userId=' + user.id).join('&');
    const requestUrl = `${this._baseUrl}/users?${ids}`;

    return this._http.delete<string>(requestUrl);
  }

  getSubcontractor$(garageId: string): Observable<SubcontractorList> {
    const requestUrl = `${this._baseUrl}/subcontractors?search=${garageId}`;
    return this._http.get<SubcontractorList>(requestUrl);
  }

  resetPassword(userId: string): Observable<void> {
    const requestUrl = `${this._baseUrl}/users/${userId}/reset-password`;
    return this._http.post<void>(requestUrl, {});
  }

  getVehicles$(subcontractorId: string): Observable<GarageVehicle[]> {
    const href = `${this._baseUrl}/subcontractors/${subcontractorId}/vehicles`;
    return this._http.get<GarageVehicle[]>(href);
  }
}
