import { Language, TravelerTranslationModel } from '../models/translation.model';

export const translationEN: TravelerTranslationModel = {
  language: Language.EN,
  searchPlaceholder: 'Search stop',
  today: 'Today',
  tomorrow: 'Tomorrow',
  stop: {
    route: 'Route',
    replacedTrain: 'Replaced train',
    towards: 'Towards',
    departs: 'Departs',
    stop: 'Stop',
    location: 'Location',
    active: 'Active',
    reason: 'Reason',
    vehicleIdentifier: 'Vehicle',
  },
  info: {
    about: 'About',
    content: "This website displays information about replacement services operated by Nobina on behalf of your train company. The goal is for you as a traveler to receive relevant information about ongoing replacement traffic, regardless of whether it is planned or urgent. The page shows all confirmed traffic for the next 24 hours. All the information you can find on this page is also fully available via API for integration into travel apps, travel planners and other services. We are Nobina, the largest public transport company in the Nordics. With us, 13,000 people work every day to find solutions to your everyday puzzle and to society's major challenges.",
    readMore: 'Read more at nobina.com',
    close: 'Close',
  },
  error: {
    affectedStopsMissing: 'There is no replacement traffic at the moment',
    stopMissing: 'No information available for this stop',
    tripMissing: 'No information available for this trip',
    close: 'Close',
  },
  seo: {
    tagline: 'Where is my replacement bus?',
    description:
      'Replacement traffic operated by Nobina on behalf of your train company. The goal is to provide you as a passenger with relevant information about ongoing replacement traffic.',
  },
};
