import { Component, DestroyRef, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationComponent } from '../navigation/navigation.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfigModelWeb, EnvironmentType } from '../../assets/config/config.model';
import { appConfigToken } from '../../utils/injection-tokens';
import { AppService } from '../../services/app.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EnvironmentService } from '../../services/environment.service';
import { LinksModel } from '../navigation/links.model';
import { SearchComponent } from '../search/search.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { NotificationsPanelComponent } from '../notifications-panel/notifications-panel.component';
import { SidePanelService } from '../../services/side-panel/side-panel.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'tbums-lib-side-nav',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    NavigationComponent,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    NgOptimizedImage,
    SearchComponent,
    UserMenuComponent,
    NotificationsPanelComponent,
    RouterModule,
  ],
  template: `<mat-sidenav-container
      autosize
      [hasBackdrop]="hasBackdrop()"
      class="static bottom-0 left-0 right-0 top-0 bg-white h-full"
    >
      <mat-sidenav
        fixedInViewport="true"
        fixedBottomGap="0"
        #sidenav
        mode="over"
        class="w-80 overscroll-x-none p-4"
        [ngClass]="environmentStyle"
      >
        <div class="mb-3 flex flex-row">
          <ng-container *ngTemplateOutlet="logo; context: { isMenu: false }"></ng-container>
        </div>

        <tbums-lib-navigation [links]="links" />
        <tbums-lib-search />
      </mat-sidenav>
      <mat-sidenav
        #sidePanel
        position="end"
        mode="over"
        class="bg-white z-[100]"
        fixedInViewport="true"
        fixedBottomGap="0"
      >
        <ng-content select="[sidePanel]"></ng-content>
      </mat-sidenav>
      <mat-sidenav-content class="static">
        <mat-toolbar class="h-16" [ngClass]="environmentStyle">
          <mat-toolbar-row class="transparent-buttons mx-auto flex h-16 max-w-screen-2xl flex-row justify-between">
            <div class="flex flex-row">
              <ng-container *ngTemplateOutlet="logo; context: { isMenu: true }"></ng-container>
            </div>
            <div>
              <tbums-lib-navigation [links]="links" class="hidden lg:block" />
            </div>
            <div>
              <tbums-lib-search class="ml-5 hidden xl:block" />
            </div>
            <div class="flex flex-row items-center">
              @if (appConfig.isSearchEnabled) {
                @if (!isSearchExternal) {
                  <a mat-icon-button [routerLink]="['/search']">
                    <mat-icon class="material-symbols-outlined">search</mat-icon>
                  </a>
                } @else {
                  <a mat-icon-button [href]="['/order-new/search']">
                    <mat-icon class="material-symbols-outlined">search</mat-icon>
                  </a>
                }
              }
              <tbums-lib-notifications-panel />
              <tbums-lib-user-menu />
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
        <ng-content select="[main]"></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <ng-template #logo let-isMenu="isMenu">
      <button mat-icon-button class="block lg:hidden" aria-label="Menu button" (click)="appService.toggleSidenav()">
        <mat-icon class="text-white">{{ isMenu ? 'menu' : 'close' }}</mat-icon>
      </button>
      <div class="relative ml-5 mr-10 lg:ml-0">
        <a href="/">
          <img
            *ngIf="appConfig.environmentType === environmentType.Production"
            ngSrc="assets/svg/nobina-logo.svg"
            width="76"
            height="39"
            alt="Nobina logo"
            [priority]="true"
          />
          <img
            *ngIf="appConfig.environmentType !== environmentType.Production"
            ngSrc="assets/svg/Nobina_logo_vit.svg"
            width="76"
            height="39"
            alt="Nobina logo"
            [priority]="true"
          />
        </a>
        <div *ngIf="appConfig.environmentType !== environmentType.Production" class="environment-label absolute">
          {{ appConfig.environmentType }}
        </div>
      </div></ng-template
    >`,
  styles: [
    `
      .environment-label {
        top: 24px;
        font-size: 14px;
        opacity: 0.7;
        color: #fff;
        left: -1px;
      }
    `,
  ],
})
export class SideNavComponent implements OnInit {
  @Input() links!: LinksModel[];
  @Input() isSearchExternal: boolean = false;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('sidePanel') sidePanel!: MatSidenav;
  appConfig = inject<ConfigModelWeb>(appConfigToken);
  appService = inject<AppService>(AppService);
  sidePanelService = inject<SidePanelService>(SidePanelService);
  environmentStyle = '';
  hasBackdrop = signal<boolean>(true);
  protected readonly environmentType = EnvironmentType;
  private _environmentService = inject(EnvironmentService);
  private _destryRef = inject(DestroyRef);

  ngOnInit() {
    this.appService.sidenav$.pipe(takeUntilDestroyed(this._destryRef)).subscribe(() => {
      this.hasBackdrop.set(true);
      this.sidenav.toggle();
      this.sidePanel.close();
    });

    this.sidePanelService.sidePanelData$.subscribe((sidePanelData) => {
      if (this.sidePanel) {
        if (sidePanelData) {
          this.hasBackdrop.set(false);
          this.sidePanel.toggle(true);
          this.sidenav.close();
        } else {
          this.sidePanel.toggle(false);
        }
      }
    });

    this.environmentStyle = this._environmentService.environmentColorClass;
  }
}
