import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigModelWeb } from '../../assets/config/config.model';
import { appConfigToken } from '../../utils/injection-tokens';
import { Subcontractor } from '../../models/subcontractor';
import { map, Observable } from 'rxjs';
import { Vehicle } from '../../models/vehicle';
import { Driver } from '../../models/driver';
import { SubcontractorOrder } from '../../models/subcontractor-order';
import { sortArrayOfObjects } from '../../utils/sort-util';

@Injectable({
  providedIn: 'root',
})
export class SubcontractorService {
  private _http: HttpClient = inject(HttpClient);
  private _appConfig: ConfigModelWeb = inject<ConfigModelWeb>(appConfigToken);

  private _baseUrl: string =
    this._appConfig.apiUrlConfig.apiUrl + this._appConfig.apiUrlConfig.apiVersionMain + '/subcontractors';

  private _baseOrderUrl: string =
    this._appConfig.apiUrlConfig.apiUrl + this._appConfig.apiUrlConfig.apiVersionMain + '/subcontractororders';

  getSubcontractors(operatorIds?: number[]): Observable<Subcontractor[]> {
    let url = this._baseUrl;

    if (operatorIds) {
      url += `?FilterByOperatorIds=${operatorIds.join(',')}`;
    }

    return this._http.get<Subcontractor[]>(url).pipe(
      map((subcontractors) => {
        return sortArrayOfObjects(subcontractors, 'name', true);
      }),
    );
  }

  getSubcontractorVehicles(id: number): Observable<Vehicle[]> {
    const url = `${this._baseUrl}/${id}/vehicles`;
    return this._http.get<Vehicle[]>(url).pipe(
      map((vehicles) => {
        return sortArrayOfObjects(vehicles, 'vehicleIdentifier', true);
      }),
    );
  }

  getVehicles(): Observable<Vehicle[]> {
    const url = `${this._baseUrl}/vehicles`;
    return this._http.get<Vehicle[]>(url).pipe(
      map((vehicles) => {
        return sortArrayOfObjects(vehicles, 'vehicleIdentifier', true);
      }),
    );
  }

  getSubcontractor(id: number): Observable<Subcontractor> {
    const url = `${this._baseUrl}/${id}`;
    return this._http.get<Subcontractor>(url);
  }

  getSubcontractorDrivers(subcontractorId: number): Observable<Driver[]> {
    const url = `${this._baseUrl}/${subcontractorId}/drivers`;
    return this._http.get<Driver[]>(url).pipe(
      map((drivers) => {
        return sortArrayOfObjects(drivers, 'lastName', true);
      }),
    );
  }

  getSubcontractorOrder(id: string): Observable<SubcontractorOrder> {
    const url = `${this._baseOrderUrl}/${id}`;
    return this._http.get<SubcontractorOrder>(url);
  }
}
