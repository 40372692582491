import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingService } from '../../services/loading.service';
import { delay, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tbums-lib-loader',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  template: `<div
    *ngIf="loading$ | async"
    [class.absolute]="externalTrigger"
    class="high-index fixed bottom-0 left-0 right-0 top-0
        flex items-center
        justify-center
        bg-black/20"
  >
    <mat-spinner></mat-spinner>
  </div>`,
  styles: [
    `
      .high-index {
        z-index: 1001;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  @Input() externalTrigger!: boolean;
  loading$!: Observable<boolean>;

  private _destryRef = inject(DestroyRef);
  private _loadingService: LoadingService = inject(LoadingService);

  ngOnInit(): void {
    if (!this.externalTrigger) {
      this.listenToLoading();
    }
  }

  listenToLoading(): void {
    this.loading$ = this._loadingService.loadingSub$
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .pipe(takeUntilDestroyed(this._destryRef));
  }
}
