import { libTranslationFI } from '../i18n/fi';
import { LibTranslation } from '../i18n/lib-translation.model';
import { libTranslationNB } from '../i18n/nb';
import { libTranslationSV } from '../i18n/sv';

export const getLibTranslation = (locale: string): LibTranslation => {
  switch (locale) {
    case 'fi':
      return libTranslationFI;
    case 'nb':
      return libTranslationNB;
    case 'sv':
      return libTranslationSV;
    default:
      return libTranslationSV;
  }
};

export function translationStringSubstitution(translation: string, ...args: string[]): string {
  args.forEach((arg, index) => {
    translation = translation.replace(`{${index}}`, arg);
  });
  return translation;
}
