import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { DriveOrderStateRequest } from '../../models/requests/drive-order-state-request';
import { DriveOrder, DriveOrderAssigned } from '../../models/drive-order';
import { format, set } from 'date-fns';
import { SplitDriveOrder } from '../../models/split-drive-order';
import { ReplaceDriveOrderTrips } from '../../models/replace-drive-order';

@Injectable({
  providedIn: 'root',
})
export class DriveOrderService {
  readonly baseUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(appConfigToken) private _appConfig: ConfigModelWeb,
    @Inject(libTranslationConfigToken) private _translation: LibTranslation,
  ) {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this.baseUrl = apiUrl + apiVersionMain + '/driveorders';
  }

  getDriveOrders$(
    orderId?: string,
    isSubcontractorOrder?: boolean,
    date?: string,
    subcontractorOrderId?: string,
    tripId?: string,
    driveOrderId?: string,
    subcontractorId?: string,
  ): Observable<DriveOrder[]> {
    let query = '?';
    if (subcontractorOrderId || orderId) {
      query += isSubcontractorOrder ? '&subcontractorOrderId=' + subcontractorOrderId : '&orderId=' + orderId;
    }

    if (subcontractorId) {
      query += '&SubcontractorId=' + subcontractorId;
    }

    if (tripId) {
      query += '&tripId=' + tripId;
    }

    if (driveOrderId) {
      query += '&driveOrderId=' + driveOrderId;
    }

    if (date) {
      query += this._getDriveOrderQueryDateTime(date);
    }
    return this._http.get<DriveOrder[]>(this.baseUrl + query);
  }

  getDriveOrder$(driveOrderId: string): Observable<DriveOrder> {
    return this._http.get<DriveOrder>(`${this.baseUrl}/${driveOrderId}`);
  }

  putDriveOrderState(
    driveOrderId: number,
    driveOrderStateRequest: DriveOrderStateRequest,
  ): Observable<HttpResponse<null>> {
    return this._http.put<HttpResponse<null>>(`${this.baseUrl}/${driveOrderId}/state`, driveOrderStateRequest);
  }

  updateAssignedSubcontractorToDriveOrder(driveOrderId: number, subcontractorId: number): Observable<unknown> {
    const url = `${this.baseUrl}/${driveOrderId}/subcontractor`;
    return this._http.put(url, { subcontractorId: subcontractorId });
  }

  postAssignVehicleToDriveOrder(driveOrderId: number, vehicleId: number): Observable<unknown> {
    const url = `${this.baseUrl}/${driveOrderId}/assignments/vehicles`;
    return this._http.post(url, { vehicleId: vehicleId });
  }

  postAssignDriverToDriveOrderTrip(
    driveOrderId: number,
    driverId: number,
    driveOrderTripId: number | null,
  ): Observable<unknown> {
    const url = `${this.baseUrl}/${driveOrderId}/assignments/drivers`;
    return this._http.post(url, {
      driverId: driverId,
      driveOrderTripId: driveOrderTripId,
    });
  }

  postCheckInTrip(driveOrderId: number, driveOrderTripId: number): Observable<unknown> {
    const url = `${this.baseUrl}/${driveOrderId}/trips/${driveOrderTripId}/checkins`;
    return this._http.post(url, {
      allowForcedCheckIn: false,
    });
  }

  changeStartTime(driveOrderId: number, newStartDate: Date): Observable<unknown> {
    const startDateStr = format(newStartDate, 'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX');

    const url = `${this.baseUrl}/${driveOrderId}/startTime`;
    return this._http.put(url, { startTime: startDateStr });
  }

  changeVehicleType(driveOrderId: number, vehicleType: number): Observable<unknown> {
    const url = `${this.baseUrl}/${driveOrderId}/vehicleType`;
    return this._http.put(url, { vehicleTypeEnum: vehicleType });
  }

  getAssignedDriveOrders(
    fromDate: string,
    toDate: string,
    vehicleId?: number | null,
    driverId?: number,
  ): Observable<DriveOrderAssigned[]> {
    const url = `${this.baseUrl}/assigneddriveorders`;
    let query = `?FromDatetime=${new Date(fromDate).toISOString()}&ToDatetime=${new Date(toDate).toISOString()}`;
    if (vehicleId) {
      query += `&vehicleId=${vehicleId}`;
    }
    if (driverId) {
      query += `&driverId=${driverId}`;
    }
    return this._http.get<DriveOrderAssigned[]>(url + query);
  }

  addPassengerCount(driveorderId: number, driveorderTripId: number, passengers: number): Observable<unknown> {
    const url = `${this.baseUrl}/${driveorderId}/trips/${driveorderTripId}/passengerCount`;
    return this._http.put(url, { passengerCount: passengers });
  }

  changeTrainNumberForTrip(driveorderId: number, driveorderTripId: number, trainNumber: string): Observable<unknown> {
    const url = `${this.baseUrl}/${driveorderId}/trips/${driveorderTripId}/trainNumber`;
    return this._http.put(url, { trainNumber: trainNumber });
  }

  postNote(
    driveOrderId: number,
    driveOrderTripId: number,
    sequenceNumber: number,
    driverComment: string | null,
    travelerComment: string | null,
  ): Observable<unknown> {
    const noteBody = {
      note: driverComment,
      travellerNote: travelerComment,
      sequenceNumber: sequenceNumber,
      driveOrderTripId: driveOrderTripId,
    };
    const url = `${this.baseUrl}/${driveOrderId}/trips/${driveOrderTripId}/note`;
    return this._http.put(url, noteBody);
  }

  cancelTrips(driveOrderId: number, tripIds: number[], reason: number, comment: string) {
    const url = `${this.baseUrl}/${driveOrderId}/trips/cancel`;
    return this._http.post(url, {
      driveOrderTripIds: tripIds,
      reasonEnum: reason,
      additionalInfo: comment,
    });
  }

  duplicateTrips(driveOrderId: number, tripIds: number[], comment: string) {
    const url = `${this.baseUrl}/${driveOrderId}/trips/duplicate`;
    return this._http.post<{ driveOrderId: number }>(url, {
      driveOrderTripIds: tripIds,
      additionalInfo: comment,
    });
  }

  replaceTrips$(driveOrderId: number, tripIds: number[], reason: string): Observable<ReplaceDriveOrderTrips> {
    const url = `${this.baseUrl}/${driveOrderId}/trips/replace`;
    return this._http.post<ReplaceDriveOrderTrips>(url, {
      driveOrderTripIds: tripIds,
      additionalInfo: reason,
    });
  }

  splitTrips$(driveOrderId: number, tripIds: number[], reason: string): Observable<SplitDriveOrder> {
    const url = `${this.baseUrl}/${driveOrderId}/trips/replace`;
    return this._http.post<SplitDriveOrder>(url, {
      driveOrderTripIds: tripIds,
      additionalInfo: reason,
    });
  }

  private _getDriveOrderQueryDateTime(dateString: string): string {
    const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss.SSSSSSSXXX';
    const date = new Date(dateString);
    const startDate = format(
      set(date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      dateTimeFormat,
    );
    const endDate = format(
      set(date, {
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      }),
      dateTimeFormat,
    );
    return `&startDateTime=${encodeURIComponent(startDate)}&endDateTime=${encodeURIComponent(endDate)}`;
  }
}
