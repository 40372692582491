export const sortArrayOfObjects = <T>(data: T[], keyToSort: keyof T, sortAscending: boolean) => {
  const compare = (objectA: T, objectB: T) => {
    const valueA = objectA[keyToSort];
    const valueB = objectB[keyToSort];

    if (valueA === valueB) {
      return 0;
    }

    if (valueA > valueB) {
      return sortAscending ? 1 : -1;
    } else {
      return sortAscending ? -1 : 1;
    }
  };

  return data.sort(compare);
};
