export interface GarageSubcontractor {
  id: number;
  garageCode: string;
  name: string;
}

export enum SubcontractorVacantType {
  None = 0,
  UeVakant = 1,
  UtforareVakant = 2,
}
