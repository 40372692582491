import { inject, Injectable } from '@angular/core';
import { MapboxDirections } from '../../models/mapbox-directions';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { appConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  private _http = inject(HttpClient);
  private _appConfig = inject<ConfigModelWeb>(appConfigToken);

  getDirections(stops: number[][], vehicleType: number | null): Observable<MapboxDirections> {
    const baseUrl = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
    let parameters =
      '?alternatives=false&annotations=duration%2Cdistance%2Cmaxspeed%2Cspeed&exclude=ferry%2Cunpaved&geometries=geojson&language=en&overview=full&steps=true';

    parameters += '&access_token=' + this._appConfig.mapboxKey;
    let stopsString = '';
    stops.forEach((stop, index) => {
      if (index !== stops.length - 1) {
        stopsString += `${stop[0]}%2C${stop[1]}%3B`;
      } else {
        stopsString += `${stop[0]}%2C${stop[1]}`;
      }
    });

    const vehicleDimensions = this.getVehicleTypeDimensions(vehicleType);

    const url = `${baseUrl}${stopsString}${parameters}${vehicleDimensions}`;
    return this._http.get<MapboxDirections>(url);
  }

  getVehicleTypeDimensions(vehicleType: number | null): string {
    switch (vehicleType) {
      case 1:
        return '&max_height=3.8&max_width=2.55&max_weight=20';
      case 2:
        return '&max_height=2.8&max_width=2&max_weight=10';
      case 3:
        return '&max_height=4.49&max_width=2.55&max_weight=27';
      case 4:
        return '&max_height=1.6&max_width=1.6&max_weight=2';
      default:
        return '';
    }
  }
}
