import { inject, Injectable } from '@angular/core';
import { LibTranslation } from '../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../utils/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class VehicleTypeService {
  vehicleTypes = new Map<number, string>();

  private _translation = inject<LibTranslation>(libTranslationConfigToken);

  constructor() {
    this.vehicleTypes.set(1, this._translation.vehicleTypes.bigBus);
    this.vehicleTypes.set(2, this._translation.vehicleTypes.smallBus);
    this.vehicleTypes.set(3, this._translation.vehicleTypes.doubleDecker);
    this.vehicleTypes.set(4, this._translation.vehicleTypes.taxi);
    this.vehicleTypes.set(5, this._translation.vehicleTypes.bigTaxi);
    this.vehicleTypes.set(6, this._translation.vehicleTypes.wheelchairTaxi);
    this.vehicleTypes.set(7, this._translation.vehicleTypes.standbyBus);
    this.vehicleTypes.set(8, this._translation.vehicleTypes.standbyWheelchairTaxi);
    this.vehicleTypes.set(9, this._translation.vehicleTypes.customerHost);
    this.vehicleTypes.set(10, this._translation.vehicleTypes.busCoordinator);
  }

  getVehicleType(vehicleTypeEnum: number): string | undefined {
    return this.vehicleTypes.get(vehicleTypeEnum);
  }
}
