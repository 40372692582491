import { AvailabilityInquiry } from './availability-inquiry';
import { CancellationDetails, DriveOrderTripStop } from './drive-order';
import { Operator } from './operator';
import { OrderStateObject } from './order-state';
import { OrderType } from './order-type';

export interface Order {
  id: number;
  orderRoute: OrderRoute;
  orderReturnRoute: OrderRoute;
  orderRouteName: string;
  referenceId: string;
  trainNumber: string;
  orderTrainNumber: string;
  startTime: string;
  endTime: string;
  driveOrderDates: string[];
  startTripStop: DriveOrderTripStop;
  endTripStop: DriveOrderTripStop;
  orderReason: OrderReason;
  numberOfVehicles: number;
  vehicleType: string;
  otherRequests: string;
  currentState: OrderStateObject;
  created: OrderStateObject;
  activated: OrderStateObject;
  availabilityInquiries: AvailabilityInquiry[];
  immediateStart: boolean;
  indefiniteEnd: boolean;
  tripIntervalMinutes: number;
  isRoundTrip: boolean;
  orderType: OrderType;
  isHastusPlanned: boolean;
  operator: Operator;
  isSpecifiedReturnTripTime: boolean;
  orderActionState: OrderActionState;
  comment: string;
  tripTrainNumbers: string[];
  cancellationDetails?: CancellationDetails | null;
}

export interface OrderWithQueryParm extends Order {
  queriedDriveOrderId: number;
  queriedStartPassTime: Date;
  queriedTripId: number;
}

export interface OrderRoute {
  id: number;
  routeName: string;
  routeDescription: string;
  routeVariantId: number;
  routeVariantName: string;
  orderRouteStops: OrderRouteStop[];
}

export interface OrderRouteStop {
  id: number;
  placeName: string;
  placeDescription: string;
  sequence: number;
  isActive: boolean;
  disembarkingOnly: boolean;
}

export interface OrderReason {
  id: number;
  description: string;
  additionalInfo: string;
}

export enum OrderActionState {
  None = 0,
  ActionNeeded = 1,
  Ongoing = 2,
}
