import { DriveOrderState, DriveOrderTrip } from '../../models/drive-order';

export interface SidePanelModel {
  type: SidePanelType;
  data: TripStopsData;
}

export enum SidePanelType {
  TripStops = 'tripStops',
}

export interface TripStopsData {
  driveOrderTrip: DriveOrderTrip;
  driveOrderId: number;
  driveOrderState: DriveOrderState | -1;
}
