import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class ApplicationInsightsService {
  appInsights!: ApplicationInsights;

  initialization(instrumentationKey: string) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights?.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({ name: name, uri: url });
  }

  logEvent(name: string, properties?: { [key: string]: unknown }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }
}
