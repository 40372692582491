import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoadingService } from '../loading.service';
import { NotificationMessage } from '../../models/notification-message';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { AuthService } from '../auth.service';
import { HubConnection, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { AccountInfo } from '@azure/msal-browser';
import { AdminService } from './admin.service';
import { AppUser } from '../../models/app-user';
import { UserRoles } from '../../models/user-roles';
import { StateChangedMessage } from '../../models/state-changed-message';
import { AlertService } from '../alert.service';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogModel } from '../../lib/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from '../../lib/confirm-dialog/confirm-dialog.component';
import { ServerNotification, ServerNotificationService } from '../server-notification.service';
import { RedirectDialogModel } from '../../lib/redirect-dialog/redirect-dialog.model';
import { RedirectDialogComponent } from '../../lib/redirect-dialog/redirect-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _isConnectionInitialized = false;
  private readonly _baseUrl: string;
  private _notifications: Subject<NotificationMessage[] | null> = new Subject<NotificationMessage[] | null>();
  private _hubConnection: HubConnection | undefined;
  private _hubUrl;
  private _lastStateChangedMessage: StateChangedMessage | null = null;
  private _lastNotification: NotificationMessage | null = null;
  private _lastServerNotification: NotificationMessage | null = null;

  private _stateChangedMessage$ = new BehaviorSubject<StateChangedMessage | null>(null);
  private _serverNotificationCount = 0;

  private _http: HttpClient = inject(HttpClient);
  private _loadingService: LoadingService = inject(LoadingService);
  private _appConfig: ConfigModelWeb = inject<ConfigModelWeb>(appConfigToken);
  private _authService: AuthService = inject(AuthService);
  private _adminService = inject(AdminService);
  private _alertService = inject(AlertService);
  private _serverNotificationService = inject(ServerNotificationService);
  private _dialog = inject(MatDialog);
  private _translation = inject<LibTranslation>(libTranslationConfigToken);

  constructor() {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this._baseUrl = apiUrl + apiVersionMain + '/notifications';
    this._hubUrl = apiUrl + 'notificationhub';
  }

  get stateChangedMessage$() {
    return this._stateChangedMessage$.asObservable();
  }

  get notifications$() {
    return this._notifications.asObservable();
  }

  getNotifications(): void {
    this._loadingService
      .runWithLoader<NotificationMessage[]>(this._getNotifications())
      .subscribe((notificationMessages: NotificationMessage[]) => this.parseNotifications(notificationMessages));
  }

  parseNotifications(notificationMessages: NotificationMessage[]) {
    const parsedNotifications: NotificationMessage[] = notificationMessages.map((message) => {
      if (message.notificationType) {
        const serverNotification: ServerNotification = this._serverNotificationService.getFormatedNotification(
          message.notificationType,
          message.data,
        );
        message.title = serverNotification.title;
        message.main = serverNotification.message;
        message.link = serverNotification.link;
      }

      return message;
    });
    this._notifications.next(parsedNotifications);
  }

  setNotificationRead(notificationIds: number[]): Observable<unknown> {
    const requestUrl = `${this._baseUrl}/userNotificationRead`;
    const request = { notificationIds: notificationIds };
    return this._http.put(requestUrl, request);
  }

  initSignalR(): void {
    this._authService.user$.subscribe((authUser) => {
      if (authUser && !this._isConnectionInitialized) {
        this._isConnectionInitialized = true;
        this._hubConnection = new HubConnectionBuilder()
          .withUrl(this._hubUrl)
          .withHubProtocol(new JsonHubProtocol())
          .configureLogging(LogLevel.Error)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              if (retryContext.elapsedMilliseconds < 60000) {
                return Math.random() * 10000;
              } else {
                return null;
              }
            },
          })
          .build();
        this._startSignalR(authUser);
        this._startStateChangeListener();
        this._startServerMessageListener();
        this._startServerNotificationListener();
        this._startConnectionListeners();
      }
    });
  }

  private _startSignalR(authUser: AccountInfo): void {
    this._adminService.getUser$().subscribe((user: AppUser) => {
      if (this._hubConnection) {
        this._hubConnection
          .start()
          .then(() => {
            const roles = authUser?.idTokenClaims?.['role'] as string[];
            roles.forEach((role) => {
              const roleGroup = 'role' + role;
              this._joinGroup(roleGroup);
              if (user?.selectedOperators) {
                user.selectedOperators.forEach((operator) => {
                  this._joinGroup(roleGroup + '_op' + operator.id);
                });
              }
            });
            if (this._authService.userHasAnyRole([UserRoles.UETrafikledare])) {
              this._joinGroup('org' + (authUser.idTokenClaims?.['garageIds'] as string[])[0]);
            }
          })
          .catch((err) => {
            console.error(err.toString());
          });
      }
    });
  }

  private _joinGroup(group: string) {
    if (this._hubConnection) {
      this._hubConnection.invoke('JoinGroup', group).then();
    }
  }

  private _startStateChangeListener(): void {
    this._hubConnection?.on('StateChangedMessage', (message: StateChangedMessage) => {
      if (
        this._lastStateChangedMessage === null ||
        !(
          message.referenceId === this._lastStateChangedMessage.referenceId &&
          message.referenceType === this._lastStateChangedMessage.referenceType &&
          message.message === this._lastStateChangedMessage.message &&
          message.updated === this._lastStateChangedMessage.updated
        )
      ) {
        this._stateChangedMessage$.next(message);
      }
      this._lastStateChangedMessage = message;
    });
  }

  private _startServerMessageListener() {
    this._hubConnection?.on('ServerMessage', (notification: NotificationMessage) => {
      if (
        this._lastNotification === null ||
        !(
          notification.link === this._lastNotification.link &&
          notification.main === this._lastNotification.main &&
          notification.submain === this._lastNotification.submain &&
          notification.title === this._lastNotification.title
        )
      ) {
        if (notification.type === 'Alert_LateDeparture') {
          this._openAlertModal(notification);
        } else {
          this._openNotificationModal({
            message: notification.main,
            link: notification.link,
            isExternal: true,
            title: notification.title,
          });
        }
      }
      this._lastNotification = notification;
      this.getNotifications();
    });
  }

  private _startServerNotificationListener() {
    this._hubConnection?.on('ServerNotification', (notification: NotificationMessage) => {
      if (
        this._lastNotification === null ||
        !(
          notification.data === this._lastServerNotification?.data &&
          notification.notificationType === this._lastServerNotification.notificationType &&
          notification.id === this._lastServerNotification.id
        )
      ) {
        if (notification.notificationType) {
          const serverNotification: ServerNotification = this._serverNotificationService.getFormatedNotification(
            notification.notificationType,
            notification.data,
          );
          this._openNotificationModal(serverNotification);
        } else {
          this._openNotificationModal({
            message: notification.main,
            link: notification.link,
            isExternal: true,
            title: notification.title,
          });
        }
      }
      this._lastServerNotification = notification;
      this.getNotifications();
    });
  }

  private _startConnectionListeners() {
    this._hubConnection?.onreconnecting(() => {
      this._alertService.setWarn(this._translation.toolbar.notifications.alert.reconnecting, 10000);
    });

    this._hubConnection?.onreconnected(() => {
      this._alertService.setSuccess(this._translation.toolbar.notifications.alert.reconnected);
    });
  }

  private _openAlertModal(notification: NotificationMessage) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      data: <ConfirmDialogModel>{
        title: notification.title,
        message: notification.main,
        cancelBtnText: this._translation.common.button.close,
        confirmBtnText: this._translation.toolbar.notifications.alert.openDriveOrder,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(notification.link);
      }
    });
  }

  private _getNotifications(unreadOnly = false, numberOfMessages = 99): Observable<NotificationMessage[]> {
    const requestUrl = `${this._baseUrl}?unreadOnly=${unreadOnly}&numberOfMessages=${numberOfMessages}`;
    return this._http.get<NotificationMessage[]>(requestUrl);
  }

  private _openNotificationModal(serverNotification: ServerNotification) {
    const dialogConfig: MatDialogConfig = {
      data: <RedirectDialogModel>{
        title: serverNotification.title,
        message: serverNotification.message,
        link: serverNotification.link,
        linkExternal: serverNotification.isExternal,
      },
      hasBackdrop: false,
      minWidth: '30rem',
      position: {
        bottom: `${20 + this._serverNotificationCount * 180}px`,
        right: '20px',
      },
      panelClass: 'server-notification',
    };

    const dialogRef = this._dialog.open(RedirectDialogComponent, dialogConfig);
    this._serverNotificationCount++;

    setTimeout(() => {
      dialogRef.close();
      this._serverNotificationCount--;
    }, 10000);
  }
}
