import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { Alert, AlertType } from '../models/alert';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private _alert$ = new Subject<Alert>();

  get alert$() {
    return this._alert$.asObservable();
  }

  setSuccess(message: string, duration?: number): void {
    this._setAlert(AlertType.Success, message, undefined, duration);
  }

  setError(message: string, operationId?: string, duration?: number): void {
    this._setAlert(AlertType.Error, message, operationId, duration);
  }

  setInfo(message: string, duration?: number): void {
    this._setAlert(AlertType.Info, message, undefined, duration);
  }

  setWarn(message: string, duration?: number): void {
    this._setAlert(AlertType.Warning, message, undefined, duration);
  }

  private _setAlert(type: AlertType, message: string, operationId?: string, duration?: number): void {
    this._alert$.next(<Alert>{ type, message, operationId, duration });
  }
}
