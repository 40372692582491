import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { OrganisationResponse } from '../../models/organisation-response';
import { AppUser } from '../../models/app-user';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly _baseUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(appConfigToken) private _appConfig: ConfigModelWeb,
    @Inject(libTranslationConfigToken) private _translation: LibTranslation,
  ) {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this._baseUrl = apiUrl + apiVersionMain + '/admin';
  }

  getMyOrganization$(): Observable<OrganisationResponse> {
    const requestUrl = `${this._baseUrl}/myInfo`;
    return this._http.get<OrganisationResponse>(requestUrl);
  }

  getUser$(): Observable<AppUser> {
    const url = `${this._baseUrl}/User`;
    return this._http.get<AppUser>(url);
  }
}
