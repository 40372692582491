import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { DateRange } from '../../models/date-range';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { UnboundOrder } from '../../models/unbound-order';

@Injectable()
export class UnboundOrderService {
  readonly baseUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(appConfigToken) private _appConfig: ConfigModelWeb,
    @Inject(libTranslationConfigToken) private _translation: LibTranslation,
  ) {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this.baseUrl = apiUrl + apiVersionMain + '/unboundorders';
  }

  /** GET orders from the server */
  getOrders$(dateRange: DateRange | undefined, unboundOrderId: number | null = null): Observable<UnboundOrder[]> {
    let url = this.baseUrl;
    if (dateRange !== undefined) {
      const { fromDate, toDate } = dateRange;
      const dateFormat = 'yyyy-MM-ddTHH:mm:ss.SSSz';
      const from = format(fromDate, dateFormat);
      const to = format(toDate, dateFormat);
      url += '?FromDateTime=' + encodeURIComponent(from);
      url += '&ToDateTime=' + encodeURIComponent(to);
    }
    if (unboundOrderId != null) {
      url += url === this.baseUrl ? '?' : '&';
      url += 'UnboundOrderId=' + unboundOrderId;
    }
    return this._http.get<UnboundOrder[]>(url);
  }
}
