import { inject, Injectable } from '@angular/core';
import { LibTranslation } from '../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../utils/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class TripStatusService {
  tripStatuses = new Map<number, string>();
  private _translation = inject<LibTranslation>(libTranslationConfigToken);
  constructor() {
    this.tripStatuses.set(1, this._translation.order.tripStatus.upcoming);
    this.tripStatuses.set(2, this._translation.order.tripStatus.ongoing);
    this.tripStatuses.set(3, this._translation.order.tripStatus.completed);
    this.tripStatuses.set(4, this._translation.order.tripStatus.upcoming);
    this.tripStatuses.set(5, this._translation.order.tripStatus.cancelled);
  }

  getTripStatusName(tripStatus: number): string | undefined {
    return this.tripStatuses.get(tripStatus);
  }
}
