import { OrderType } from './order-type';
import { StateObject } from './state-object';
import { TripStopSummary } from './drive-order';
import { Driver } from './driver';
import { Vehicle } from './vehicle';
import { AvailableVehicle } from './availability-inquiry';

export interface SubcontractorOrder {
  id: number;
  orderRoute: SubcontractorOrderRoute;
  immediateStart: boolean;
  orderType: OrderType;
  orderId: number;
  orderTrainNumber: string;
  subcontractorId: number;
  subcontractorName: string;
  availabilityInquiryId: number;
  numberOfVehiclesAssigned: number;
  estimatedTimeOfArrivalMinutes: number;
  assignedVehicles: AvailableVehicle[];
  currentState: StateObject;
  orderRouteName: string;
  startTime: string;
  endTime: string;
  driveOrderDates: string[];
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  orderReason: SubcontractorOrderReason;
  orderedByNobina: StateObject;
  isRoundTrip: boolean;
  startOrderRouteStopDescription: string;
  endOrderRouteStopDescription: string;
}

export interface SubcontractorOrderTrips {
  totalItems: number;
  items: SubcontractorOrderListItem[];
}

export interface SubcontractorOrderListItem {
  id: number;
  orderRoute: SubcontractorOrderRoute;
  immediateStart: boolean;
  orderType: OrderType;
  orderId: number;
  tripId: number;
  orderTrainNumber: string;
  subcontractorId: number;
  subcontractorName: string;
  availabilityInquiryId: number;
  numberOfVehiclesAssigned: number;
  estimatedTimeOfArrivalMinutes: number;
  currentState: StateObject;
  orderRouteName: string;
  startTime: Date;
  endTime: Date;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  orderReason: SubcontractorOrderReason;
  orderedByNobina: StateObject;
  isRoundTrip: boolean;
  startOrderRouteStopDescription: string;
  endOrderRouteStopDescription: string;
  vehicle: Vehicle;
  driver: Driver;
}

export interface SubcontractorOrderRoute {
  routeName: string;
  routeVariantName: string;
}

export interface SubcontractorOrderReason {
  id: number;
  description: string;
  additionalInfo: string;
}

export interface SubcontractorOrderRequest {
  orderId: number;
  subcontractorId: number;
  availabilityInquiryId: number;
  assignedVehicles: AvailableVehicle[];
  numberOfVehiclesAssigned: number;
  estimatedTimeOfArrivalMinutes: number;
}

export enum SubcontractorOrderState {
  OrderedByNobina = 0,
  Assigned = 1,
  Ongoing = 2,
  Completed = 3,
}
