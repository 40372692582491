export enum EnvironmentType {
  Local = 'Local',
  ST = 'ST',
  FT = 'FT',
  AT = 'AT',
  Production = 'Production',
}

export interface RegionConfig {
  enableBilling: boolean;
  enableUnboundOrder: boolean;
  locale: 'sv_SE' | 'no_NO' | 'fi_FI' | 'da_DK';
  language: 'sv' | 'nb' | 'fi' | 'da';
}

export interface ApiUrlConfig {
  apiUrl: string;
  apiVersionMain: string;
  outcomeUrl: string;
  apiVersionOutcome: string;
  positionUrl: string;
  garageUrl: string;
  apiVersionGarage: string;
  routeUrl: string;
  apiVersionRoute: string;
}

export interface OAuthConfig {
  mainServiceScopes: string[];
  garageServiceScopes: string[];
  outcomeServiceScopes: string[];
  routeServiceScopes: string[];
  authority: string;
  authorityDomain: string;
  clientId: string;
  tenantId: string;
  redirectUrl: string;
}

export interface ConfigModelBase {
  apiUrlConfig: ApiUrlConfig;
  appInsights: {
    instrumentationKey: string;
  };
  oauth: OAuthConfig;
  environmentType: EnvironmentType;
  buildId?: string;
  //commit hash
  versionId?: string;
  isPlanningEnabled: boolean;
  isPlanningCalendarEnabled: boolean;
  isPlanningUEActionsEnabled: boolean;
  isApplicationInsightsEnabled: boolean;
  isRouteWebEnabled: boolean;
  isSearchEnabled: boolean;
  mapboxKey: string;
}

export interface ConfigModelFull extends ConfigModelBase {
  se: RegionConfig;
  no: RegionConfig;
  fi: RegionConfig;
  da: RegionConfig;
}

export interface ConfigModelWeb extends ConfigModelBase {
  region: RegionConfig;
}
