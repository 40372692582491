import { inject, Injectable } from '@angular/core';
import { LibTranslation } from '../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../utils/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class OrderTypeService {
  orderTypes = new Map<number, string>();
  private _translation = inject<LibTranslation>(libTranslationConfigToken);
  constructor() {
    this.orderTypes.set(1, this._translation.order.orderType.urgent);
    this.orderTypes.set(2, this._translation.order.orderType.unplanned);
    this.orderTypes.set(3, this._translation.order.orderType.planned);
    this.orderTypes.set(4, this._translation.order.orderType.unbound);
  }

  getOrderType(orderTypeEnum: number): string | undefined {
    return this.orderTypes.get(orderTypeEnum);
  }
}
